import React, { useState } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'; // Correct import for Day.js adapter
import dayjs from 'dayjs';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Paper,
  Button,
  Box,
  Select,
  MenuItem,
  Checkbox,
  Collapse,
  Grid
} from '@mui/material';
import { ocorrenciasOptions } from '../../ocorrenciasOptions';
import SaveIcon from '@mui/icons-material/Save';
import { MenuOpen, Save, AttachFile, Add, CloudDownload } from '@mui/icons-material';

const EditableTableDoc2Efetivo = () => {
  const [rowData, setRowData] = useState([
    {
      servidorId: 1,
      nome: 'Aline Santos Silva',
      matricula: '34413',
      cargo: 'Doc II',
      funcao: 'Regente',
      turma: 'R1 - 401',
      turno: '2º',
      ch: '20h',
      chcfcSim: false,
      chcfcNao: true,
      ocorrencias: [],
    },
    {
      nome: 'RONALDO TESTE',
      matricula: '34413',
      cargo: 'Doc II',
      funcao: 'Regente',
      turma: 'R1 - 401',
      turno: '2º',
      ch: '20h',
      chcfcSim: false,
      chcfcNao: true,
      ocorrencias: [
        {
          ocorrencia: 'INTEGRAL',
          servidorPermutado: '',
          dataInicio: dayjs(), // Initialize with dayjs()
          dataFim: dayjs(), // Initialize with dayjs()
          diasAusente: 0,
          file: 'fdfdsafsdadf',
        }
      ],
    },
  ]);

  // State to control the visibility of occurrences
  const [expandedRows, setExpandedRows] = useState(
    rowData.map(() => false) // Initialize with all occurrences collapsed
  );

  const toggleExpand = (index) => {
    const newExpandedRows = [...expandedRows];
    newExpandedRows[index] = !newExpandedRows[index];
    setExpandedRows(newExpandedRows);
  };

  const handleChange = (event, field, servidorIndex, ocorrenciaIndex) => {
    const updatedRows = [...rowData];
    if (typeof ocorrenciaIndex !== 'undefined') {
      updatedRows[servidorIndex].ocorrencias[ocorrenciaIndex] = {
        ...updatedRows[servidorIndex].ocorrencias[ocorrenciaIndex],
        [field]: event.target.value,
      };
    } else {
      updatedRows[servidorIndex] = {
        ...updatedRows[servidorIndex],
        [field]: event.target.value,
      };
    }
    setRowData(updatedRows);
  };

  const handleCheckboxChange = (field, servidorIndex) => {
    const updatedRows = [...rowData];
    updatedRows[servidorIndex] = {
      ...updatedRows[servidorIndex],
      [field]: !updatedRows[servidorIndex][field],
    };
    setRowData(updatedRows);
  };

  const handleDateChange = (value, field, servidorIndex, ocorrenciaIndex) => {
    const updatedRows = [...rowData];
    updatedRows[servidorIndex].ocorrencias[ocorrenciaIndex] = {
      ...updatedRows[servidorIndex].ocorrencias[ocorrenciaIndex],
      [field]: value && dayjs(value).isValid() ? dayjs(value) : null,
    };
    setRowData(updatedRows);
  };

  const handleFileChange = (e) => {
    setRowData({
      ...rowData,
      file: e.target.files[0],
    });
  };

  const handleDownloadFile = (file) => {
    const fileUrl = URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = file.name;
    link.click();
  };

  const calculateDiasAusente = (servidorIndex, ocorrenciaIndex) => {
    const row = rowData[servidorIndex].ocorrencias[ocorrenciaIndex];
    if (row.dataInicio && row.dataFim && dayjs(row.dataInicio).isValid() && dayjs(row.dataFim).isValid()) {
      const diffDays = dayjs(row.dataFim).diff(dayjs(row.dataInicio), 'day');
      return diffDays > 0 ? diffDays : 0;
    }
    return 0;
  };

  const handleSaveServidor = (servidorIndex) => {
    console.log(`Saved Servidor Data for ${rowData[servidorIndex].nome}:`, rowData[servidorIndex]);
  };

  const handleSaveOcorrencia = (servidorIndex, ocorrenciaIndex) => {
    console.log(`Saved Ocorrencia Data for ${rowData[servidorIndex].nome}:`, rowData[servidorIndex].ocorrencias[ocorrenciaIndex]);
  };

  const handleAddOcorrencia = (servidorIndex, ocorreciaTable = false) => {
    const newOcorrencia = {
      ocorrencia: 'INTEGRAL',
      servidorPermutado: '',
      dataInicio: null,
      dataFim: null,
      diasAusente: 0,
      file: null,
    };

    const updatedRows = [...rowData];
    updatedRows[servidorIndex].ocorrencias.push(newOcorrencia);
    setRowData(updatedRows);
    if (ocorreciaTable === false){
        toggleExpand(servidorIndex);
    }
    // toggleExpand(servidorIndex); // Expand the row when adding an occurrence
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nº</TableCell>
                <TableCell>NOME</TableCell>
                <TableCell>MATR.</TableCell>
                <TableCell>CARGO</TableCell>
                <TableCell>FUNÇÃO</TableCell>
                <TableCell>TURMA</TableCell>
                <TableCell>TURNO</TableCell>
                <TableCell>C.H.</TableCell>
                <TableCell>CHCFC SIM</TableCell>
                <TableCell>CHCFC NÃO</TableCell>
                <TableCell>AÇÃO</TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{backgroundColor: 'white'}}>
              {rowData.map((servidor, servidorIndex) => (
                <React.Fragment key={servidorIndex}>
                  <TableRow style={{backgroundColor: 'white'}}>
                    <TableCell>{servidorIndex + 1}</TableCell>
                    <TableCell>{servidor.nome}</TableCell>
                    <TableCell>{servidor.matricula}</TableCell>
                    <TableCell>{servidor.cargo}</TableCell>
                    <TableCell>{servidor.funcao}</TableCell>
                    <TableCell>{servidor.turma}</TableCell>
                    <TableCell>{servidor.turno}</TableCell>
                    <TableCell>
                      <TextField
                        value={servidor.ch}
                        onChange={(e) => handleChange(e, 'ch', servidorIndex)}
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={servidor.chcfcSim}
                        onChange={() => handleCheckboxChange('chcfcSim', servidorIndex)}
                      />
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={servidor.chcfcNao}
                        onChange={() => handleCheckboxChange('chcfcNao', servidorIndex)}
                      />
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleSaveServidor(servidorIndex)}
                      >
                        <SaveIcon />
                      </Button>
                      {servidor.ocorrencias.length > 0 ? (
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() => toggleExpand(servidorIndex)}
                          sx={{ marginLeft: 1 }}
                        >
                          {expandedRows[servidorIndex] ? <MenuOpen /> : <MenuOpen />}
                        </Button>
                      ) : (
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() => handleAddOcorrencia(servidorIndex)}
                          sx={{ marginLeft: 1 }}
                        >
                          <Add />
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>

                  {servidor.ocorrencias.length > 0 && (
                    <TableRow style={{backgroundColor: 'white'}}>
                      <TableCell colSpan={11}>
                        <Collapse in={expandedRows[servidorIndex]}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Ocorrência</TableCell>
                                <TableCell>Servidor Permutado</TableCell>
                                <TableCell>Data Início</TableCell>
                                <TableCell>Data Fim</TableCell>
                                <TableCell>Dias Ausente</TableCell>
                                <TableCell>Anexo</TableCell>
                                <TableCell>Ação</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {servidor.ocorrencias.map((ocorrencia, ocorrenciaIndex) => (
                                <TableRow key={ocorrenciaIndex} style={{backgroundColor: 'white'}}>
                                  <TableCell>
                                    <Select
                                      value={ocorrencia.ocorrencia}
                                      onChange={(e) =>
                                        handleChange(e, 'ocorrencia', servidorIndex, ocorrenciaIndex)
                                      }
                                      variant="outlined"
                                      size="small"
                                      fullWidth
                                    >
                                      {ocorrenciasOptions.map((option) => (
                                        <MenuItem key={option} value={option}>
                                          {option}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </TableCell>
                                  <TableCell>
                                    <TextField
                                      value={ocorrencia.servidorPermutado}
                                      onChange={(e) =>
                                        handleChange(e, 'servidorPermutado', servidorIndex, ocorrenciaIndex)
                                      }
                                      variant="outlined"
                                      size="small"
                                      fullWidth
                                      disabled={ocorrencia.ocorrencia !== 'PERMUTA'}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <DatePicker
                                      label="Data Início"
                                      value={ocorrencia.dataInicio}
                                      onChange={(value) =>
                                        handleDateChange(value, 'dataInicio', servidorIndex, ocorrenciaIndex)
                                      }
                                      renderInput={(params) => <TextField {...params} fullWidth />}
                                      disabled={!ocorrencia.ocorrencia.includes('LICENÇA') && ocorrencia.ocorrencia !== 'ATESTADO MÉDICO'}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <DatePicker
                                      label="Data Fim"
                                      value={ocorrencia.dataFim}
                                      onChange={(value) =>
                                        handleDateChange(value, 'dataFim', servidorIndex, ocorrenciaIndex)
                                      }
                                      renderInput={(params) => <TextField {...params} fullWidth />}
                                      disabled={!ocorrencia.ocorrencia.includes('LICENÇA') && ocorrencia.ocorrencia !== 'ATESTADO MÉDICO'}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <TextField
                                      label="Dias Ausente"
                                      value={calculateDiasAusente(servidorIndex, ocorrenciaIndex)}
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                      fullWidth
                                    />
                                  </TableCell>
                                  <TableCell>
                                    {ocorrencia.file ? (
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        onClick={() => handleDownloadFile(ocorrencia.file)}
                                      >
                                        <CloudDownload />
                                        Download
                                      </Button>
                                    ) : (
                                      <Button variant="contained" component="label" fullWidth>
                                        <AttachFile />
                                        Upload
                                        <input
                                          type="file"
                                          hidden
                                          accept="application/pdf, image/png"
                                          onChange={(e) => handleFileChange(e, servidorIndex, ocorrenciaIndex)}
                                        />
                                      </Button>
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      onClick={() => handleSaveOcorrencia(servidorIndex, ocorrenciaIndex)}
                                    >
                                      <SaveIcon />
                                    </Button>
                                    <Button
                                      variant="outlined"
                                      color="secondary"
                                      sx={{ marginLeft: 1 }}
                                      onClick={() => handleAddOcorrencia(servidorIndex, true)}
                                    >
                                      <Add />
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </LocalizationProvider>
  );
};

export default EditableTableDoc2Efetivo;
