import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useMutation } from 'react-query'
import { postListaEspera } from '../../../../../services/processos/remanejamento'
import ProcessosSeletivos from './tab/formulario'


const RemanejamentoOnline = () => {

  const { mutate } = useMutation(postListaEspera, {
    onSuccess: data => {
      toast.success('Formulário enviado com sucesso.');
    },
    onError: data => {
      toast.error('Erro ao enviar formulário. Tente novamente mais tarde.')
    }
  })

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { isValid, errors }
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      bairro: '',
      celularResponsavel1: '',
      celularResponsavel2: '',
      celularResponsavel3: '',
      cep: '',
      cidade: '',
      complemento: '',
      cpfAluno: '',
      cpfResponsavelLegal1: '',
      cpfResponsavelLegal2: '',
      cpfResponsavelLegal3: '',
      dataNascimento: '',
      emailResponsavel1: '',
      emailResponsavel2: '',
      emailResponsavel3: '',
      estado: '',
      logradouro: '',
      necessidadesEducacionaisEspeciais: '',
      nomeAluno: '',
      numero: 0,
      numeroCartaoSus: '',
      possuiBolsaFamilia: false,
      possuiLaudo: false,
      guardaOficial: false,
      termoDeclaracao: false,
      responsavelLegal1: '',
      responsavelLegal2: '',
      responsavelLegal3: '',
      telefoneResponsavel1: '',
      telefoneResponsavel2: '',
      telefoneResponsavel3: '',
    }
  })

  function removeEmpty(obj) {
    return Object.entries(obj).reduce((a, [k, v]) => (v ? (a[k] = v, a) : a), {})
  }

  const onSubmit = data => {

    if (data) {
      const request = {
        ...data,
        possuiBolsaFamilia: Boolean(data.possuiBolsaFamilia),
        possuiLaudo: Boolean(data.possuiLaudo),
        guardaOficial: Boolean(data.guardaOficial),
        termoDeclaracao: Boolean(data.termoDeclaracao),
        necessidadesEducacionaisEspeciais: Boolean(data.necessidadesEducacionaisEspeciais),
        numero: parseInt(data.numero),
      }
      mutate(request)
    }
  }

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      padding: '24px 48px',
      backgroundImage: 'url("https://pre-matricula.educacaoararuama.rj.gov.br/_form/images/fundo.jpg")',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundAttachment: 'fixed',
      height: '100vh'
    }}>
      <img src='https://pre-matricula.educacaoararuama.rj.gov.br/assets/araruama/araruama-logo-principal.png' width="200" height="150"></img>
      <h6 style={{ margin: '15px', alignSelf: 'center' }}>PREFEITURA MUNICIPAL DE ARARUAMA</h6>
      <h4 style={{ marginTop: '10px', alignSelf: 'center' }}>SECRETARIA DE EDUCAÇÃO</h4>
      <div className="row">
        <div className="mailbox-container">
          <div className="card">
            <div className="container-fluid" style={{background: '#fff'}}>
              <div className="row">
                <div className="mailbox-open-content col-md-12 m-0 p-3">
                  <div className="tab-content" id="myTabContent">
                    <ProcessosSeletivos />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RemanejamentoOnline
