import api from '..'

async function putUpdateAluno({ id, data }) {

  return await api
    .put(`aluno/${id}`, {
      ...data,
    })
    .then(async (res) => {
      const { message } = res.data;
      return message;
    });
}

async function getAlunosFiltro(unidadeId = 0, escolaridadeId = 0, turnoId = 0, turmaId = 0, matriculados = 1, renovacao = 0, nome = "null", cpf = "", responsavel = "", skip = 0, limit = 20, anoLetivo = 2023, utilizaTransporteEscolar = 0) {
    return await api
      .get(`aluno?unidadeId=${unidadeId}&escolaridadeId=${escolaridadeId}&turnoId=${turnoId}&turmaId=${turmaId}&matriculados=${matriculados}&renovacao=${renovacao}&nome=${nome}&cpf=${cpf}&responsavel=${responsavel}&skip=${skip}&limit=${limit}&anoLetivo=${anoLetivo}&utilizaTransporteEscolar=${utilizaTransporteEscolar}`)
      .then(async (res) => {
        const { data } = res;
        return data;
      });
  };

  async function getAlunosFiltroEvadidos(unidadeId = 0, escolaridadeId = 0, turnoId = 0, turmaId = 0, nome = "null", cpf = "", responsavel = "", skip = 0, limit = 20) {
    return await api
      .get(`aluno/evadidos?unidadeId=${unidadeId}&escolaridadeId=${escolaridadeId}&turnoId=${turnoId}&turmaId=${turmaId}&nome=${nome}&cpf=${cpf}&responsavel=${responsavel}&skip=${skip}&limit=${limit}`)
      .then(async (res) => {
        const { data } = res;
        return data;
      });
  };

  async function restaurarAluno(data){
    return await api
      .post('/aluno/restaurar', data)
      .then(async (res) => {
        const { message } = res.data;
        return message;
      });
  }

  async function adicionarDocumento(data){
    return await api
      .post('/aluno/documento', data)
      .then(async (res) => {
        const { data } = res;
        return data;
      });
  }

  async function listarDocumentosAluno(alunoId){
    return await api
      .get(`/aluno/documento/${alunoId}`)
      .then(async (res) => {
        const { data } = res;
        return data;
      });
  }

  async function deletarDocumentosAluno(documentoId){
    return await api
      .delete(`/aluno/documento/${documentoId}`)
      .then(async (res) => {
        const { data } = res;
        return data;
      });
  }

  async function getAlunosCpf(cpf = '') {
    return await api
      .get(`aluno/validacaoCpf?cpf=${cpf}`)
      .then(async (res) => {
        const { data } = res;
        return data;
      });
  };
  
  async function getAlunos() {
    return await api
      .get('aluno')
      .then(async (res) => {
        const { data } = res.data;
        return data;
      });
  };
  
  async function deleteAluno(id) {
    return await api
      .delete(`aluno/${id}`)
      .then(async (res) => {
        const { message } = res.data;
        return message;
      });
  }
  
  async function createAluno(data) {
    return await api
      .post('aluno', data)
      .then(async (res) => {
        const { message } = res.data;
        return message;
      });
  }
  
  async function updateAluno({ id, data }) {
    return await api
      .put(`aluno/${id}`, data)
      .then(async (res) => {
        const { message } = res.data;
        return message;
      });
  }
  
  async function getAluno(id) {
    return await api
      .get(`aluno/${id}`)
      .then(async (res) => {
        const { data } = res.data;
        return data;
      });
  }
  
  async function getAlunoByName(name = '', skip = 0, limit = 20) {
    return await api
      .get(`aluno/nome=${name}/skip=${skip}&limit=${limit}`)
      .then(async (res) => {
        const { data } = res.data;
        return data;
      });
  }
  
  async function getAlunosByTurma(turma = '', skip = 0, limit = 20) {
    return await api
      .get(`aluno/turma=${turma}/skip=${skip}&limit=${limit}`)
      .then(async (res) => {
        const { data } = res;
        return data;
      });
  }
  
  async function getAlunosByTurma2024(turma = '', skip = 0, limit = 20) {
    return await api
      .get(`aluno/turma2024=${turma}/skip=${skip}&limit=${limit}`)
      .then(async (res) => {
        const { data } = res;
        return data;
      });
  }
  
  
  async function getAlunosByUnidadeEscolaridadeTurnoTurma(unidade, escolaridade, turno, turma) {
    return await api
      .get(`aluno?unidadeId=${unidade}&escolaridadeId=${escolaridade}&turnoId=${turno}&turmaId=${turma}`)
      .then(async (res) => {
        const { data } = res;
        return data;
      });
  }
  
  async function aprovarAluno(data) {
    return await api
      .post('aluno/aprovar', data)
      .then(async (res) => {
        const { message } = res.data;
        return message;
      });
  }

  async function aprovarAlunoEja(data) {
    return await api
      .post('aluno/renovacao/eja', data)
      .then(async (res) => {
        const { message } = res.data;
        return message;
      });
  }
  
  async function getAlunosTranferenciaRecebidas(unidadeProxima = 0, skip = 0, limit = 20) {
    return await api
      .get(`aluno/transferencias/recebidas?unidadeProxima=${unidadeProxima}&skip=${skip}&limit=${limit}`)
      .then(async (res) => {
        const { data } = res;
        return data;
      });
  }
  
  async function getAlunosTranferenciaEnviadas(unidadeProxima = 0, skip = 0, limit = 20) {
    return await api
      .get(`aluno/transferencias/enviadas?unidadeAtual=${unidadeProxima}&skip=${skip}&limit=${limit}`)
      .then(async (res) => {
        const { data } = res;
        return data;
      });
  }
  
  async function aprovarTransferencia(data) {
    return await api
      .post(`aluno/transferencias/aprovar`, data)
      .then(async (res) => {
        const { data } = res;
        return data;
      });
  }
  
  async function recusarTransferencia(data) {
    return await api
      .post(`aluno/transferencias/recusar`, data)
      .then(async (res) => {
        const { data } = res;
        return data;
      });
  }
  
  async function logsAluno(alunoId) {
    return await api
      .get(`aluno/logs?alunoId=${alunoId}`)
      .then(async (res) => {
        const { data } = res;
        return data;
      });
  }

  async function postEvadirAluno(data) {
    return await api
      .post('/aluno/evasao', data, {
      })
      .then(async (res) => {
        const { message } = res.data;
        return message;
      });
  }


async function getAlunosSemMatriculaByUnidade(nome) {
  const unidadeId = await localStorage.getItem('unidadeID');
  const url = nome && nome.length > 0 ? `aluno/unidade/${unidadeId}/sem-matricula/nome=${nome}` : `aluno/unidade/${unidadeId}/sem-matricula`;
  return await api
    .get(url)
    .then(async (res) => {
      const { data } = res.data;
      return data;
    });
};

  async function getAlunosSemMatriculaByUnidadeSearch(search) {
    const unidadeId = await localStorage.getItem('unidadeID');
    if (search.length === 0) {
      return await getAlunosSemMatriculaByUnidade();
    }
    return await api
      .get(`aluno/unidade/${unidadeId}/sem-matricula/nome=${search}`)
      .then(async (res) => {
        const { data } = res.data;
        return data;
      });
  };
  
  
  export {
    getAlunos,
    deleteAluno,
    createAluno,
    updateAluno,
    getAluno,
    getAlunoByName,
    getAlunosFiltro,
    getAlunosByTurma,
    getAlunosByTurma2024,
    aprovarAluno,
    getAlunosTranferenciaEnviadas,
    getAlunosTranferenciaRecebidas,
    aprovarTransferencia,
    recusarTransferencia,
    getAlunosByUnidadeEscolaridadeTurnoTurma,
    logsAluno,
    putUpdateAluno,
    postEvadirAluno,
    getAlunosSemMatriculaByUnidadeSearch,
    getAlunosSemMatriculaByUnidade,
    getAlunosCpf,
    getAlunosFiltroEvadidos,
    restaurarAluno,
    aprovarAlunoEja,
    adicionarDocumento,
    listarDocumentosAluno,
    deletarDocumentosAluno
  }