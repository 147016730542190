import urlsAdministrativo from "./urlsAdministrativo";

const linksAdministrativo = (currentUser) => {
  return {
    administrativo: {
      id: 'administrativo',
      text: 'ADMINISTRATIVO',
      type: 'title',
      visible: true,
      itens: {
        dashboard: {
          id: 'dashboard',
          text: 'Dashboard',
          path: urlsAdministrativo.dashboard,
          icon: 'dashboard',
          visible: true,
        },
        // Uncomment and use these items if needed
        transporte: {
          id: 'transporte',
          text: 'Transporte',
          path: urlsAdministrativo.administrativoTransporte,
          icon: 'commute',
          visible: false,
        },
        alimentacao: {
          id: 'alimentacao',
          text: 'Alimentação',
          path: urlsAdministrativo.administrativoAlimentacao,
          icon: 'restaurant',
          visible: false,
        },
        acompanhamentoNutricional: {
          id: 'acompanhamentoNutricional',
          text: 'Acomp Nutricional',
          path: urlsAdministrativo.administrativoAcompanhamentoNutricional,
          icon: 'set_meal',
          visible: false,
        },
      }
    },
    patrimonio: {
      id: 'patrimonio',
      text: 'PATRIMÔNIO',
      type: 'title',
      visible: true,
      itens: {
        fornecedores: {
          id: 'fornecedores',
          text: 'Fornecedores',
          path: urlsAdministrativo.patrimonioControleFornecedores,
          icon: 'group',
          visible: true,
        },
        contratos: {
          id: 'contratos',
          text: 'Contratos',
          path: urlsAdministrativo.patrimonioControleContratos,
          icon: 'assignment',
          visible: true,
        },
        categoriaProdutos: {
          id: 'categoriaProdutos',
          text: 'Categoria de Produtos',
          path: urlsAdministrativo.patrimonioCategoriaProdutos,
          icon: 'category',
          visible: true,
        },
        produtos: {
          id: 'produtos',
          text: 'Produtos',
          path: urlsAdministrativo.patrimonioControleProdutos,
          icon: 'border_color',
          visible: true,
        },
        estoque: {
          id: 'estoque',
          text: 'Controle de estoque',
          path: urlsAdministrativo.patrimonioControleEstoque,
          icon: 'warehouse',
          visible: true,
        },
        solicitacao: {
          id: 'solicitacao',
          text: 'Solicitações',
          path: urlsAdministrativo.administrativoPatrimonioControleSolicitacao,
          icon: 'content_paste_go',
          visible: false,
        },
      }
    },
    ti: {
      id: 'ti',
      text: 'TI',
      type: 'title',
      visible: false,
      itens: {
        impressoras: {
          id: 'impressoras',
          text: 'Impressoras',
          path: urlsAdministrativo.impressoras,
          icon: 'print',
          visible: true,
        },
        // Uncomment and use this item if needed
        relatorio: {
          id: 'relatorio',
          text: 'Relatório Impressoras',
          path: urlsAdministrativo.administrativoPatrimonioControleImpressorasRelatorios,
          icon: 'report',
          visible: true,
        },
      }
    },
    financeiro: {
      id: 'financeiro',
      text: 'FINANCEIRO',
      type: 'title',
      visible: false,
      itens: {
        processos: {
          id: 'processos',
          text: 'Processos',
          path: urlsAdministrativo.processos,
          icon: 'folder_special',
          visible: true,
        },
        contasPagar: {
          id: 'contasPagar',
          text: 'Contas a pagar',
          path: urlsAdministrativo.contasPagar,
          icon: 'request_quote',
          visible: true,
        },
        memorando: {
          id: 'memorando',
          text: 'Memorandos',
          path: urlsAdministrativo.memorando,
          icon: 'class',
          visible: true,
        },
      }
    },
    nutricao: {
      id: 'nutricao',
      text: 'NUTRIÇÃO',
      type: 'title',
      visible: false,
      itens: {
        fornecedores: {
          id: 'fornecedores',
          text: 'Fornecedores',
          path: urlsAdministrativo.fornecedores,
          icon: 'group',
          visible: true,
        },
        categoriaProdutos: {
          id: 'categoriaProdutos',
          text: 'Categoria Produtos',
          path: urlsAdministrativo.administrativoNutricaoCategoriaProduto,
          icon: 'category',
          visible: true,
        },
        produtos: {
          id: 'produtos',
          text: 'Produtos',
          path: urlsAdministrativo.produtos,
          icon: 'border_color',
          visible: true,
        },
        controlePedido: {
          id: 'controlePedido',
          text: 'Controle de Pedidos ',
          path: urlsAdministrativo.nutricaoControlePedidoCadastrar,
          icon: 'assignment',
          visible: true,
        },
        movimentacoes: {
          id: 'movimentacoes',
          text: 'Movimentações',
          path: urlsAdministrativo.nutricaoMovimentacoes,
          icon: 'warehouse',
          visible: true,
        },
        cardapio: {
          id: 'cardapio',
          text: 'Cardápio',
          path: urlsAdministrativo.nutricaoCardapio,
          icon: 'content_paste_go',
          visible: true,
        },
      }
    },
    transporte: {
      id: 'transporte',
      text: 'TRANSPORTE',
      type: 'title',
      visible: currentUser?.moduloAdministrativoTransporte === 1,
      itens: {
        funcionariosDashboard: {
          id: 'funcionariosDashboard',
          text: 'Dashboard',
          path: urlsAdministrativo.transporteDashboard,
          icon: 'group',
          visible: false,
        },
        alunos: {
          id: 'alunos',
          text: 'Alunos',
          path: urlsAdministrativo.transporteAlunos,
          icon: 'group',
          visible: true,
        },
        funcionarios: {
          id: 'funcionarios',
          text: 'Funcionários',
          path: urlsAdministrativo.transporteFuncionarios,
          icon: 'group',
          visible: false,
        },
        veiculos: {
          id: 'veiculos',
          text: 'Veículos',
          path: urlsAdministrativo.transporteVeiculos,
          icon: 'group',
          visible: false,
        },
        quadroHorario: {
          id: 'quadroHorario',
          text: 'Quadro de Horários',
          path: urlsAdministrativo.transporteQuadroHorario,
          icon: 'group',
          visible: false,
        },
      }
    },
  };
}

export default linksAdministrativo;
