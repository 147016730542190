import { useEffect, useMemo, useState } from "react";
import { set, useForm } from "react-hook-form";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Breadcrumb from "../../../components/breadcrumb";
import BaseGestao from "../../gestao/baseGestao";
import BaseGestaoUnidades from "../../gestao-unidades/baseGestaoUnidades";
import urlsGestao from "../../gestao/urlsGestao";
import urlsGestaoUnidades from "../../gestao-unidades/urlsGestaoUnidades";
import { useMutation } from 'react-query';
import { createTurma, updateTurma } from "../../../services/turmas";
import DadosTurma from "./tab/dadosTurma";
import api from "../../../services";
import removeEmpty from "../../../utils/removeEmpty";
import upperCaseSimple from "../../../utils/upperCaseSimple";
import { Alert } from '@mui/material'

const TurmasAdicionarPage = (gestao) => {
  
  const turmasUrl = gestao.gestao ? urlsGestao.turmas : urlsGestaoUnidades.turmas;
  
  const BaseComponent = gestao.gestao ? BaseGestao : BaseGestaoUnidades;
  const BasePath = gestao.gestao ? 'Gestão' : 'Gestão Unidades';

  const navigate = useNavigate();
  const { state } = useLocation();
  const [canEdit, setCanEdit] = useState(state?.id ? false : true);
  const [newData, setNewData] = useState('');
  const unidadeID = localStorage.getItem('unidadeID');

  const getUnitName = async () => {
    try {
      const res = await api.get(`unidade/${unidadeID}`)
      setNewData(res.data.data.nome)
      setValue('unidadeID', res.data.data.nome)
    } catch (err) {
      console.log({ err })
    }
  }

  useEffect(() => {
    getUnitName()
  }, []);

  const { mutate, isLoading } = useMutation(state?.id ? updateTurma : createTurma, {
    onSuccess: () => {
      navigate(turmasUrl);
    },
    onError: (error) => {
      if (error.response) {
        const { message } = error.response.data;
        toast.error(message);
      } else if (error.request) {
        toast.error('Erro ao tentar cadastrar o servidor, tente novamente mais tarde.');
      } else {
        toast.error(error.message);
      }
    }
  });

  const { register, handleSubmit, setValue,watch, formState: { isValid, errors } } = useForm({
    mode: "onChange"
  });


  const onSubmit = (formData) => {
    if (state?.id) {
      const id = state?.id
      const data = {
        ...upperCaseSimple(removeEmpty(formData)),
        metragemSala: parseFloat(formData.metragemSala),
        vagas: parseFloat(formData.vagas),
        vagasPcd: parseInt(formData.vagasPcd),
        vagasTotais: parseInt(formData.vagasTotais),
        preMatricula: parseInt(formData.preMatricula),
        dataAbertura: formData.dataAbertura,
        dataFechamento: formData.dataFechamento
      }
      mutate({ id, data });
    } else {
      const data = {
        ...upperCaseSimple(removeEmpty(formData)),
        metragemSala: parseFloat(formData.metragemSala),
        vagas: parseFloat(formData.vagas),
        vagasPcd: parseInt(formData.vagasPcd),
        vagasTotais: parseInt(formData.vagasTotais),
        preMatricula: parseInt(formData.preMatricula),
        dataAbertura: formData.dataAbertura,
        dataFechamento: formData.dataFechamento
      }
      mutate({ ...data, unidadeID: unidadeID });
    }
  };

  useMemo(() => {
    (async () => {
      if (state) {
        console.log(state)
        const { turma, id } = state;
        if (id && turma) {
          const { descricao, escolaridadeID, turnoID, vagas = 0, metragemSala = 0, anoLetivo, vagasPcd, preMatricula, vagasTotais, dataAbertura, dataFechamento } = turma
          setValue('id', id)
          setValue('unidadeID', newData)
          setValue('descricao', descricao)
          setValue('vagas', vagas)
          setValue('metragemSala', metragemSala)
          setValue('escolaridadeID', escolaridadeID)
          setValue('turnoID', turnoID)
          setValue('anoLetivo', anoLetivo)
          setValue('vagasPcd', vagasPcd)
          setValue('preMatricula', preMatricula)
          setValue('vagasTotais', vagasTotais)
          setValue('dataAbertura', new Date(dataAbertura).toISOString().split('T')[0])
          setValue('dataFechamento', new Date(dataFechamento).toISOString().split('T')[0])
        }
      }
    })();
  }, [state?.id, state, setValue]);

  // ao cancelar edição mantém os dados já recebidos no state;

  return (
    <BaseComponent>
      <Breadcrumb title={'Formulário Nova Turma'} itens={[BasePath, 'Turmas', 'Formulário']} />
      {(state?.id && 
        !canEdit && 
        new Date(state?.turma?.dataFechamento).toISOString().split('T')[0] !== '0001-01-01' && 
        new Date(state?.turma?.dataFechamento).toISOString().split('T')[0] < new Date().toISOString().split('T')[0]
      ) && (
        <Alert variant="filled" severity="info" style={{marginBottom: 20, marginTop: 20}}>
          Não é possível editar essa turma, ela está fechada!
        </Alert>
      )}

     
      <div className='row'>
        <div className="mailbox-container">
          <div className="card">
            <div className="container-fluid">
              <div className="row">
                <div className="mailbox-open-content col-md-12 m-0 p-3">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button className="nav-link active" id="dadosTurma-tab" data-bs-toggle="tab" data-bs-target="#dadosTurma"
                        type="button" role="tab" aria-controls="dadosTurma" aria-selected="true">
                        Dados da turma
                      </button>
                    </li>

                  </ul>
                  {/* <form id='form'> */}



                  <form id='form' onSubmit={handleSubmit(onSubmit)}>
                    <div className="tab-content" id="myTabContent">
                      <DadosTurma
                        register={register}
                        errors={errors}
                        setValue={setValue}
                        canEdit={canEdit}
                        isEditPage={state?.id ? true : false}
                        escolaridadeInfo={state?.turma.escolaridadeID || null}
                        watch={watch}
                      />
                    </div>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
                    <button style={{marginRight: 10}} type="button" id="edit" className='btn btn-warning' onClick={() => navigate(-1)}>
                          Voltar
                        </button>

                      {
                        canEdit && (
                          <button type='submit' className='btn btn-success' disabled={!isValid || !canEdit}>
                            Cadastrar
                          </button>
                        )
                      }

{
                    (state?.id && 
                      !canEdit && 
                      (new Date(state?.turma?.dataFechamento).toISOString().split('T')[0] === '0001-01-01' || 
                      new Date(state?.turma?.dataFechamento).toISOString().split('T')[0] > new Date().toISOString().split('T')[0])
                    ) && (
                    
                        <button type="button" id="edit" className='btn btn-success' onClick={() => setCanEdit(true)}>
                          Editar
                        </button>

                      
                    )
                  }
                       </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div >
      </div >
    </BaseComponent>
  )

};

export default TurmasAdicionarPage;