import Breadcrumb from "../../../../../components/breadcrumb";
import BaseChamadaPublica from "../../baseChamadaPublica";
import {
  listarFiltroUnidades,
  listarFiltroEscolaridades,
  listarInscricoes,
  postConvocar,
  listarTotalConvocados,
} from "../../../../../services/processos/chamadaPublica";
import { useNavigate } from "react-router-dom";
import { useMemo, useEffect, useState, useRef } from "react";
import { useQuery, useMutation } from "react-query";
import urlsChamadaPublica from "../../urlsChamadaPublica";
import Table from "../../../../../components/table";
import { store } from "../../../../../store";
import { toast } from "react-toastify";
import { getTurmasUnidadeEscolaridadeTurno } from "../../../../../services/turmas";
import Select from "react-select";
import { Chip, Menu, Button as ButtonComponet } from "@mui/material";
import { FilterAltOutlined } from "@mui/icons-material";

const ChamadaPublicInscritosListagemPageCreche = (parametros) => {
  const navigate = useNavigate();

  const [totalConvocados, setTotalConvocados] = useState(0);
  const [totalRemanejamentoEntrada, setTotalRemanejamentoEntrada] = useState(0);
  const [totalRemanejamentoSaida, setTotalRemanejamentoSaida] = useState(0);

  let unidadeFiltro =
    localStorage.getItem("chamadaPublicaUnidadeFiltroP") === null
      ? 0
      : localStorage.getItem("chamadaPublicaUnidadeFiltroP");
  let escolaridadeFiltro =
    localStorage.getItem("chamadaPublicaEscolaridadeFiltroP") === null
      ? 0
      : localStorage.getItem("chamadaPublicaEscolaridadeFiltroP");
  let statusFiltro = parametros.parametros;
  let deficienteFiltro =
    localStorage.getItem("chamaPublicaDeficienteFiltroP") === null
      ? 0
      : localStorage.getItem("chamaPublicaDeficienteFiltroP");
  let redeFiltro =
    localStorage.getItem("chamaPublicaRedeFiltroP") === null
      ? 0
      : localStorage.getItem("chamaPublicaRedeFiltroP");
  let nomeFiltro =
    localStorage.getItem("chamaPublicaNomeFiltroFiltroP") === null
      ? ""
      : localStorage.getItem("chamaPublicaNomeFiltroFiltroP");

  const [data, setData] = useState([]);
  const [unidade, setUnidade] = useState(unidadeFiltro);
  const [escolaridade, setEscolaridade] = useState(escolaridadeFiltro);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(20);
  const [status, setStatus] = useState(statusFiltro);
  const [deficiente, setDeficiente] = useState(deficienteFiltro);
  const [rede, setRede] = useState(redeFiltro);
  const [nome, setNome] = useState(nomeFiltro);

  const currentUser = store.getState()["user"]["user"];

  const [unidadesData, setUnidadesData] = useState([]);

  const onMenuOpen = () => setIsOpen(true);
  const onMenuClose = () => setIsOpen(false);
  const [isOpen, setIsOpen] = useState(false);

  const {
    data: unidades,
    isLoading: loadingUnidades,
    refetch: refetchUnidades,
  } = useQuery(
    "listarFiltroUnidades",
    () => listarFiltroUnidades(parametros.parametros),
    {
      retry: true,
      enabled: true,
      onSuccess: (data) => {
        let dados = [{ value: 0, label: "" }];
        data.forEach((item) => {
          dados.push({ value: item.id, label: item.nome });
        });
        setUnidadesData(dados);
      },
    }
  );

  const {
    data: escolaridades,
    isLoading: loadingEscolaridades,
    refetch: refetchEscolaridades,
  } = useQuery(
    "getEscolaridade",
    () => listarFiltroEscolaridades(parametros.parametros, unidade),
    {
      retry: 0,
      enabled: unidade > 0,
    }
  );

  const {
    data: turmas,
    isLoading: loadingTurmas,
    refetch: refetchTurmas,
  } = useQuery(
    "getTurmas",
    () =>
      getTurmasUnidadeEscolaridadeTurno(unidade, escolaridade, 0, 2024, 0, 20),
    {
      retry: 0,
      enabled: unidade > 0 && escolaridade > 0,
    }
  );

  async function convocarAluno(inscricaoId) {
    // Confirm before proceeding
    const isConfirmed = window.confirm("Deseja realmente convocar este aluno?");
    if (!isConfirmed) {
      // User clicked 'Cancel', do nothing
      return;
    }
    const data = {
      usuarioId: parseInt(currentUser?.servidorId),
      unidadeId: parseInt(unidade),
      inscricaoId: inscricaoId,
    };

    if (unidade == 0 || escolaridade == 0) {
      toast.warning(
        "Selecione uma unidade e uma escolaridade para convocar o aluno."
      );
      return;
    }

    const response = await postConvocar(data);

    if (response.statusCode !== 200) {
      toast.error("Erro ao convocar aluno.");
      return;
    } else {
      toast.success("Aluno convocado com sucesso.");
      refetchInscricoes();
    }
  }

  const {
    isError,
    isLoading,
    refetch: refetchInscricoes,
  } = useQuery(
    "inscricoes",
    () =>
      listarInscricoes(
        unidade,
        escolaridade,
        status,
        deficiente,
        rede,
        skip,
        limit,
        nome
      ),
    {
      retry: 0,
      enabled: true,
      onSuccess: (data) => {
        if (parametros.bloquearFiltros) {
          const convocados = listarTotalConvocados(unidade, escolaridade);
          setTotalConvocados(convocados?.data?.totalConvocados);
          setTotalRemanejamentoEntrada(
            convocados?.data?.totalRemanejamentoEntrada
          );
          setTotalRemanejamentoSaida(convocados?.data?.totalRemanejamentoSaida);
        }
        setData(data);
      },
    }
  );

  const statusArray = [
    // { id: 0, value: 'SEM STATUS' },
    { id: 1, value: "LISTA DE ESPERA" },
    { id: 2, value: "CONVOCADO" },
    { id: 3, value: "MATRICULADO" },
    { id: 4, value: "NÃO COMPARECIDO" },
    { id: 5, value: "INDEFERIDO" },
    { id: 6, value: "CANCELADO" },
    { id: 7, value: "DESISTÊNCIA" },
    { id: 8, value: "MATRICULADO POR OUTRA OPÇÃO" },
    { id: 9, value: "CONVOCADO POR OUTRA OPÇÃO" },
    { id: 10, value: "MATRICULADO EM OUTRA OPÇÃO" },
  ];

  const filtroComuns = [
    { id: 0, value: "" },
    { id: 1, value: "SIM" },
    { id: 2, value: "NÃO" },
  ];

  const handleStatusChange = async (e) => {
    const newStatus = parseInt(e.target.value);
    localStorage.setItem("chamaPublicaStatusFiltro", newStatus);
    setStatus(newStatus);
  };

  useEffect(() => {
    refetchInscricoes();

    if (unidade > 0 && escolaridade > 0) {
      refetchEscolaridades();
    }
  }, [
    unidade,
    escolaridade,
    limit,
    skip,
    nome,
    status,
    deficiente,
    rede,
    refetchInscricoes,
  ]);

  const turmasColluns = useMemo(
    () => [
      { Header: "#", accessor: "id" },
      { Header: "Nome Turma", accessor: "descricao" },
      { Header: "PreMatricula", accessor: "preMatricula" },
      { Header: "Vagas Totais", accessor: "vagasTotais" },
      // { Header: 'Alunos', accessor: 'quantidadeAlunos' },
      // { Header: 'Sala (m²)', accessor: 'metragemSala' },
      // { Header: 'Ano Letivo', accessor: 'anoLetivo' },
      { Header: "Vagas Regulares", accessor: "vagas" },
      { Header: "Vagas PcD", accessor: "vagasPcd" },
      { Header: "Alunos Regulares", accessor: "quantidadeAlunosRegulares" },
      { Header: "Alunos PcD", accessor: "quantidadeAlunosPcdPne" },
      {
        Header: "Saldo",
        accessor: "saldo",
        Cell: ({ row }) => {
          let saldo = 0;

          if (process.env.REACT_APP_SECRETARIA_CIDADE === "ARARUAMA") {
            const vagas = row.original.vagasTotais;
            const alunosRegulares = row.original.quantidadeAlunosRegulares;
            const alunosPcdPne = row.original.quantidadeAlunosPcdPne;

            saldo = vagas - (alunosRegulares + alunosPcdPne * 2);
          }

          if (process.env.REACT_APP_SECRETARIA_CIDADE !== "SEDUC") {
            const vagas = row.original.vagasTotais;
            const alunosRegulares = row.original.quantidadeAlunosRegulares;
            const alunosPcdPne = row.original.quantidadeAlunosPcdPne;

            saldo = vagas - (alunosRegulares + alunosPcdPne * 3);
          }

          return (
            <span
              style={{
                color: saldo < 0 ? "red" : "inherit",
                fontWeight: saldo < 0 ? "bold" : "normal",
              }}
            >
              {saldo}
            </span>
          );
        },
      },
    ],
    [navigate]
  );

  const columns = useMemo(
    () => [
      { Header: "#", accessor: "id" },
      { Header: "Opção", accessor: "opcaoEscolhidaNumeral" },
      { Header: "Unidade Escolhida", accessor: "opcaoEscolhidaDescricao" },
      { Header: "Nome", accessor: "nome" },
      { Header: "Responsável", accessor: "responsavelNomeCompleto" },
      {
        Header: "Nascimento",
        accessor: "dataNascimento",
        Cell: ({ row }) =>
          row.original.dataNascimento
            .split("T")[0]
            .split("-")
            .reverse()
            .join("/"),
      },
      {
        Header: "Criação",
        accessor: "criacao",
        Cell: ({ row }) =>
          row.original.criacao.split("T")[0].split("-").reverse().join("/") +
          " " +
          row.original.criacao.split("T")[1].split(".")[0],
      },
      {
        Header: "Status",
        accessor: "statusOpcaoEscolhidaDescricao",
        Cell: ({ row }) => (
          <span className={`badge bg-dark`}>
            {row.original.statusOpcaoEscolhidaDescricao}
          </span>
        ),
      },
      {
        Header: "Deficiente",
        accessor: "deficiente",
        Cell: ({ row }) => (
          <span
            className={`badge bg-${
              row.original.deficiente == 1 ? "success" : "danger"
            }`}
          >
            {row.original.deficiente == 1 ? "SIM" : "NÃO"}
          </span>
        ),
      },
      {
        Header: "Estudante da Rede",
        accessor: "estudanteDaRede",
        Cell: ({ row }) => (
          <span
            className={`badge bg-${
              row.original.estudanteDaRede == 1 ? "success" : "danger"
            }`}
          >
            {row.original.estudanteDaRede == 1 ? "SIM" : "NÃO"}
          </span>
        ),
      },
      {
        Header: "",
        accessor: "actions",
        Cell: ({ row }) => (
          <>
            <button
              onClick={() => {
                window.open(
                  urlsChamadaPublica.visualizacao + row.original.id,
                  "_blank"
                );
              }}
              className="btn btn-sm btn-primary"
            >
              <i className="material-icons-two-tone">edit_note</i> visualizar
            </button>

            <button
              onClick={() =>
                navigate(urlsChamadaPublica.historico + "/" + row.original.id, {
                  state: {
                    id: row.original.id,
                    unidade: row.original.opcaoEscolhidaNumeral,
                    unidadeNome: row.original.opcaoEscolhidaDescricao,
                    nome: row.original.nome,
                  },
                })
              }
              className="btn btn-sm btn-warning"
            >
              <i className="material-icons-two-tone">edit_note</i> histórico
            </button>
            {parametros.bloquearFiltros &&
              [257, 2429, 3029, 3030].includes(
                parseInt(currentUser?.servidorId)
              ) && (
                <button
                  onClick={() => convocarAluno(row.original.id)}
                  className="btn btn-sm btn-success"
                >
                  <i className="material-icons-two-tone">edit_note</i> convocar
                </button>
              )}
          </>
        ),
      },
    ],
    [navigate]
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseB = () => {
    setAnchorEl(null);
  };

  const cleanFilters = async () => {
    await setUnidade(0);
    await setEscolaridade(0);
    await setDeficiente(0);
    await setStatus(0);
    await setRede(0);
    await refetchEscolaridades();
    await refetchTurmas();
    await refetchInscricoes();
    await refetchUnidades();
    localStorage.removeItem("chamaPublicaDeficienteFiltroP");
    localStorage.removeItem("chamadaPublicaEscolaridadeFiltroP");
    localStorage.removeItem("chamaPublicaRedeFiltroP");
    localStorage.removeItem("chamadaPublicaUnidadeFiltroP");
  };

  const unidadeSelecionada = unidadesData?.find(
    (x) => x.value == unidade
  )?.label;
  const escolaridadeSelecionada =
    escolaridade > 0
      ? escolaridades?.find((x) => x.id == escolaridade)?.nome
      : "TODOS";
  const estudanteDaRedeSelecionado =
    rede == 1 ? "SIM" : rede == 2 ? "NÃO" : "TODOS";
  const deficienteSelecionado =
    deficiente == 1 ? "SIM" : deficiente == 2 ? "NÃO" : "TODOS";
  const statusSelecionado =
    status > 0 ? statusArray.find((x) => x.id == status)?.value : "TODOS";

  return (
    <BaseChamadaPublica>
      <Breadcrumb
        title={"Inscrições"}
        itens={[
          "Chamada Pública",
          parametros.parametros == 1 ? "Inscrições Creche" : "Inscrições Pré",
          "Lista Geral",
        ]}
        // hasExcelButton
      />

      <div className="row" style={{ marginTop: 20, marginBottom: 40 }}>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            gap: "12px",
          }}
        >
          <ButtonComponet
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            startIcon={<FilterAltOutlined />}
            variant="contained"
            size="large"
            color="info"
          >
            Filtro
          </ButtonComponet>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleCloseB}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <div style={{ padding: "20px", width: "400px" }}>
              <div className="form-group">
                <label htmlFor="unidade" className="form-label">
                  Unidades
                </label>
                <Select
                  aria-labelledby="aria-label"
                  inputId="aria-example-input"
                  name="aria-live-color"
                  onMenuOpen={onMenuOpen}
                  onMenuClose={onMenuClose}
                  options={unidadesData}
                  value={unidadesData?.find((x) => x.value == unidade)}
                  placeholder=""
                  onChange={async (e) => {
                    await setUnidade(e.value);
                    await refetchEscolaridades();
                    setEscolaridade(0);
                    await refetchInscricoes();
                    localStorage.setItem(
                      "chamadaPublicaUnidadeFiltroP",
                      parseInt(e.value)
                    );
                  }}
                />
              </div>
              <div className="form-group">
                <label htmlFor="escolaridade" className="form-label">
                  Escolaridades
                </label>
                <select
                  className="form-select"
                  id="escolaridade"
                  value={escolaridade}
                  // disabled={parametros.bloquearFiltros}
                  onChange={async (e) => {
                    await setEscolaridade(e.target.value);
                    await localStorage.setItem(
                      "chamadaPublicaEscolaridadeFiltroP",
                      parseInt(e.target.value)
                    );
                  }}
                >
                  <option value={0}></option>
                  {escolaridades?.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.nome}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="status" className="form-label">
                  Status
                </label>
                <select
                  className="form-select"
                  id="status"
                  value={status}
                  disabled={parametros.bloquearFiltros}
                  onChange={handleStatusChange}
                >
                  <option value={0}></option>
                  {statusArray?.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.value}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="deficiente" className="form-label">
                  Deficiente?
                </label>
                <select
                  className="form-select"
                  id="deficiente"
                  value={deficiente}
                  disabled={parametros.bloquearFiltros}
                  onChange={async (e) => {
                    await setDeficiente(e.target.value);
                    await localStorage.setItem(
                      "chamaPublicaDeficienteFiltroP",
                      e.target.value
                    );
                  }}
                >
                  {/* <option value={0}>s</option> */}
                  {filtroComuns?.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.value}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="estudanteDaRede" className="form-label">
                  Estudante Da Rede?
                </label>
                <select
                  className="form-select"
                  id="estudanteDaRede"
                  value={rede}
                  disabled={parametros.bloquearFiltros}
                  onChange={async (e) => {
                    await setRede(e.target.value);
                    await localStorage.setItem(
                      "chamaPublicaRedeFiltroP",
                      e.target.value
                    );
                  }}
                >
                  {filtroComuns?.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.value}
                    </option>
                  ))}
                </select>
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Chip
                  sx={{ marginTop: 2 }}
                  color="error"
                  label="Limpar filtros"
                  onClick={cleanFilters}
                />
              </div>
            </div>
          </Menu>
          {unidade > 0 ? (
            <Chip
              color="info"
              label={`UNIDADE: ${unidadeSelecionada}`}
              variant="outlined"
              onDelete={async () => {
                await setUnidade(0);
                await setEscolaridade(0);
                localStorage.removeItem("chamadaPublicaUnidadeFiltroP");
                await refetchEscolaridades();
                await refetchInscricoes();
              }}
            />
          ) : null}
          {escolaridade > 0 ? (
            <Chip
              color="info"
              label={`ESCOLARIDADE: ${escolaridadeSelecionada}`}
              variant="outlined"
              onDelete={async () => {
                await setEscolaridade(0);
                await localStorage.removeItem(
                  "chamadaPublicaEscolaridadeFiltroP"
                );
              }}
            />
          ) : null}
          {status > 0 ? (
            <Chip
              color="info"
              label={`STATUS: ${statusSelecionado}`}
              variant="outlined"
              onDelete={async () => {
                await setStatus(0);
              }}
            />
          ) : null}
          {deficiente > 0 ? (
            <Chip
              color="info"
              label={`DEFICIENTE: ${deficienteSelecionado}`}
              variant="outlined"
              onDelete={async () => {
                await setDeficiente(0);
                localStorage.removeItem("chamaPublicaDeficienteFiltroP");
              }}
            />
          ) : null}
          {rede > 0 ? (
            <Chip
              color="info"
              label={`ESTUDANTE DA REDE: ${estudanteDaRedeSelecionado}`}
              variant="outlined"
              onDelete={async () => {
                await setRede(0);
                await localStorage.removeItem("chamaPublicaRedeFiltroP");
              }}
            />
          ) : null}
        </div>

        <div className="col-12 col-md-12" style={{ marginTop: "10px" }}>
          <div className="form-group">
            <label htmlFor="regimeId" className="form-label">
              Pesquisar
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Pesquisar"
              value={nome}
              disabled={parametros.bloquearFiltros}
              onChange={(e) => setNome(e.target.value)}
            />
          </div>
        </div>

        {turmas &&
          turmas.data &&
          turmas.data.length > 0 &&
          parametros.bloquearFiltros && (
            <div className="row" style={{ marginTop: 20 }}>
              <div className="col-12">
                <h4>Dados Turmas</h4>
                <div className="card">
                  <Table
                    columns={turmasColluns}
                    data={turmas.data}
                    hasPagination
                    limit={limit}
                    setLimit={setLimit}
                    skip={skip}
                    setSkip={setSkip}
                    totalItems={turmas.total}
                    hiddenColluns={["preMatricula"]}
                  />
                </div>
              </div>
            </div>
          )}

        <div className="row" style={{ marginTop: 20 }}>
          <div className="col-12">
            {parametros.bloquearFiltros && (
              <h4>
                Inscrições Lista Espera | Total Convocados:{" "}
                <b>{totalConvocados}</b> | Total Remanejamento Recebidos:{" "}
                <b>{totalRemanejamentoEntrada}</b> | Total Remanejamento
                Enviados: <b>{totalRemanejamentoSaida}</b>
              </h4>
            )}
            {parametros.bloquearFiltros == false && <h4>Inscrições</h4>}

            <div className="card">
              <Table
                columns={columns}
                data={data?.data ? data?.data : []}
                hasPagination
                limit={data?.limit}
                setLimit={setLimit}
                skip={data?.skip}
                setSkip={setSkip}
                totalItems={data?.total}
                hiddenColluns={["opcaoEscolhidaDescricao"]}
              />
            </div>
          </div>
        </div>
      </div>
    </BaseChamadaPublica>
  );
};
export default ChamadaPublicInscritosListagemPageCreche;
