import React from 'react';
import { Routes, Route } from 'react-router-dom';
import RelatorioAlunosTransporte from '../../pages/relatorios/alunos/alunosTransporte';

const defaultPath = '/desenvolvimento';

const DesenvolvimentoRoutes = () => (
    <React.Fragment>
        {/* <Route exact path={`${defaultPath}/1`} element={ <RelatorioAlunosTransporte />}/> */}
       
        
    </React.Fragment>
);

export default DesenvolvimentoRoutes;
