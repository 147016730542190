import React from 'react';
import EditableTableDoc2Efetivo from '../tables/doc2Efetivo';
const Doc2Efetivo = () => {
  return (
    <div style={{marginTop: 20}}>
      {/* <h2>DOC. II EFETIVO</h2> */}
      <EditableTableDoc2Efetivo />
    </div>
  );
};

export default Doc2Efetivo;
