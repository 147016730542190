/* eslint-disable jsx-a11y/anchor-is-valid */
import { NavLink, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Types as PanelTypes } from "../../store/ducks/panel";
import { useState, useEffect } from "react";
import { getUnidades, getUnidadesById } from "../../services/unidades";
import { quadroHorarioServidorUnidades } from "../../services/quadro-horario";
import { store } from "../../store/index";
import PanicModal from "../panicModal";
import Select, { AriaOnFocus } from "react-select";

const Header = ({
  title,
  isGestao,
  showPicker,
  docente,
  loadUnidades = true,
}) => {
  const isMenuOpen = useSelector((state) => state.panel.isMenuOpen);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const [modulosInfo, setModulosInfo] = useState();
  const [panicModalOpen, setPanicModalOpen] = useState(false);
  const [openPicker, setOpenPicker] = useState(false);
  const [idAlocation, setIdAlocation] = useState(
    JSON.parse(localStorage.getItem("unidadeID"))
  );
  const [unidadesFiltro, setUnidadesFiltro] = useState([]);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const onMenuOpen = () => setIsOpen(true);
  const onMenuClose = () => setIsOpen(false);

  const currentUser = store.getState()["user"]["user"];
  const location = useLocation();

  const modulos =
    JSON.parse(localStorage.getItem("infosModulos"))?.filter(
      (e) => e.status !== 0
    ) || [];

  // const { data: unidades } = useQuery('unidades5', isGestao ? getUnidades : getUnidadesById(currentUser?.servidorId), {
  //   retry: false
  // })

  useEffect(
    () => {
      async function fetchData() {
        try {
          if (loadUnidades) {
            if (isGestao) {
              const data = await getUnidades();
              var dados = [];
              data.forEach((item) => {
                dados.push({
                  value: item.id,
                  label: item.id + " - " + item.nome,
                });
              });

              // dados.sort((a, b) => {
              //   if (a.label < b.label) {
              //     return -1;
              //   }
              //   if (a.label > b.label) {
              //     return 1;
              //   }
              //   // a deve ser igual a b
              //   return 0;
              // });

              setUnidadesFiltro(dados);
            }
            if (docente) {
              const data = await quadroHorarioServidorUnidades(
                currentUser?.servidorId
              );
              var dados = [];
              data.forEach((item) => {
                dados.push({
                  value: item.id,
                  label: item.id + " - " + item.nome,
                });
              });

              // dados.sort((a, b) => {
              //   if (a.label < b.label) {
              //     return -1;
              //   }
              //   if (a.label > b.label) {
              //     return 1;
              //   }
              //   // a deve ser igual a b
              //   return 0;
              // });

              setUnidadesFiltro(dados);
              const unidadeId = JSON.parse(localStorage.getItem("unidadeID"));
              if (dados.find((e) => e.value === unidadeId) === undefined) {
                localStorage.setItem("unidadeID", dados[0].value);
                title = dados[0].label;
              }
            }
            if (!isGestao && !docente) {
              const data = await getUnidadesById(currentUser?.servidorId);
              var dados = [];
              data.forEach((item) => {
                dados.push({
                  value: item.id,
                  label: item.id + " - " + item.nome,
                });
              });

              // dados.sort((a, b) => {
              //   if (a.label < b.label) {
              //     return -1;
              //   }
              //   if (a.label > b.label) {
              //     return 1;
              //   }
              //   // a deve ser igual a b
              //   return 0;
              // });

              setUnidadesFiltro(dados);
            }
            setLoading(false);
          }
        } catch (error) {
          setLoading(false);
        }
      }
      setModulosInfo(modulos.filter((e) => e.status !== false));
      localStorage.setItem("unidadeID", idAlocation);
      fetchData();
    },
    [idAlocation],
    []
  );

  return (
    <div className="app-header">
      <nav className="navbar navbar-light navbar-expand-lg">
        <div className="container-fluid">
          <PanicModal
            open={panicModalOpen}
            onClose={() => setPanicModalOpen(false)}
          />
          <div className="navbar-nav" id="navbarNav">
            <ul className="navbar-nav">
              {/*TOOGLE MENU*/}
              {location.pathname !== "/perfil" ? (
                <li className="nav-item">
                  <a
                    id="titleUnidade"
                    className="nav-link hide-sidebar-toggle-button"
                    onClick={() =>
                      dispatch({
                        type: PanelTypes.OPEN_MENU,
                        payload: { isMenuOpen: !isMenuOpen },
                      })
                    }
                  >
                    <>
                      <i className="material-icons">menu_open</i> {title}
                    </>
                  </a>
                </li>
              ) : null}
            </ul>
          </div>

          <div className="d-flex">
            <ul className="navbar-nav">
              {/*UNIDADES*/}
              {showPicker && !openPicker ? (
                <li className="nav-item">
                  <a
                    className="nav-link text-dark"
                    onClick={() => setOpenPicker(!openPicker)}
                  >
                    <i className="material-icons">edit</i> Selecionar unidade
                  </a>
                </li>
              ) : showPicker ? (
                <li className="nav-item">
                  {openPicker && (
                    <div style={{ width: "350px", marginTop: "20px" }}>
                      <Select
                        aria-labelledby="aria-label"
                        inputId="aria-example-input"
                        name="aria-live-color"
                        onMenuOpen={onMenuOpen}
                        onMenuClose={onMenuClose}
                        options={unidadesFiltro}
                        value={unidadesFiltro.find(
                          (x) =>
                            x.value ==
                            JSON.parse(localStorage.getItem("unidadeID"))
                        )}
                        onChange={(e) => {
                          setIdAlocation(e.value);
                          setOpenPicker(false);
                          window.location.reload();
                        }}
                      />
                    </div>
                    // <select
                    //   className="form-select "
                    //   id="unidade"
                    //   onChange={e => {
                    //     setIdAlocation(e.target.value);
                    //     setOpenPicker(false);
                    //     window.location.reload()
                    //   }}
                    //   value={idAlocation}
                    // >
                    //   {unidades?.map(unidade => (
                    //     <option key={unidade.id} value={unidade.id}>
                    //       {unidade.nome}
                    //     </option>
                    //   ))}
                    // </select>
                  )}
                </li>
              ) : null}

              {/*MODULOS*/}
              {modulosInfo && modulosInfo.length > 0 && (
                <li className="nav-item dropdown hidden-on-mobile">
                  <a
                    style={
                      openPicker ? { marginTop: "15px" } : { marginTop: "0" }
                    }
                    className="nav-link dropdown-toggle text-dark"
                    href="/modulos"
                    id="exploreDropdownLink"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="material-icons-two-tone">view_module</i>{" "}
                    Módulos
                  </a>
                  <ul
                    className="dropdown-menu dropdown-lg large-items-menu"
                    aria-labelledby="exploreDropdownLink"
                    style={{ height: "480px", overflow: "auto" }}
                  >
                    <li>
                      <h6 className="dropdown-header">Módulos</h6>
                    </li>
                    {modulosInfo.map((e) => (
                      <li key={e.link}>
                        <NavLink
                          to={e.link.includes(".br") ? e.link : `/${e.link}`}
                          className="dropdown-item"
                        >
                          <h5 className="dropdown-item-title">
                            {e.titulo}
                            <span className="hidden-helper-text">
                              trocar
                              <i className="material-icons">
                                keyboard_arrow_right
                              </i>
                            </span>
                          </h5>
                          <span className="dropdown-item-description">
                            {e.descricao}
                          </span>
                        </NavLink>
                      </li>
                    ))}
                    <li className="dropdown-btn-item d-grid">
                      <a href="/modulos" className="btn btn-primary text-white">
                        Voltar pra tela de módulos
                      </a>
                    </li>
                  </ul>
                </li>
              )}

              {/*PÂNICO*/}
              <button
                className="nav-link text-danger"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                  border: "none",
                  marginRight: "12px",
                  backgroundColor: "transparent",
                }}
                onClick={() => setPanicModalOpen(true)}
              >
                <i className="material-icons">warning</i> PÂNICO
              </button>
              {/*PÂNICO*/}

              <li className="nav-item ">
                <a
                  href="https://r3mais.tomticket.com/kb/modulo"
                  className="nav-link nav-link"
                >
                  <i className="material-icons-two-tone">help</i> Ajuda
                </a>
              </li>

              {/*LOGOUT*/}
              <li className="nav-item hidden-on-mobile">
                <a className="nav-link text-dark" href="/">
                  <i
                    className="material-icons"
                    style={{ paddingBottom: "2px", marginRight: "2px" }}
                  >
                    logout
                  </i>
                  Sair
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
