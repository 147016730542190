import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import * as S from './styles';
import { store } from '../../store/index';
import api from '../../services/index';

export default function Notifications({notifications}) {
  const currentUser = store.getState()['user']['user'];
  const [isActive, setIsActive] = useState('notificacao');
  const [isFilterActive, setIsFilterActive] = useState('nao-lidas');
  // Estados que controlam a paginação
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState(null);
  const [dataTab, setDataTab] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        setData(notifications.data.data);
        setDataTab(notifications.data.notificacoes || []);
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    }
    fetchData();
  }, []);

  

  useEffect(() => {
    if (data) {
      if (isFilterActive === 'lidas') {
        setCurrentPage(1);
        setDataTab(isActive === 'notificacao'
          ? data.notificacoes.filter(e => !!e.lida).slice(0, 15)
          : data.avisos.filter(e => !!e.lida).slice(0, 15)
        );
      } else if (isFilterActive === 'nao-lidas') {
        setCurrentPage(1);
        setDataTab(isActive === 'notificacao'
          ? data.notificacoes.filter(e => !e.lida).slice(0, 15)
          : data.avisos.filter(e => !e.lida).slice(0, 15)
        );
      }
    }
  }, [isFilterActive, isActive, data]);

  const backgroundColor = (number) => {
    let colorRgb = '';
    switch (number) {
      case 1:
        colorRgb = '#ff9999'; // 80
        break;
      case 2:
        colorRgb = '#ffff99';
        break;
      default:
        colorRgb = 'none';
        break;
    }
    return colorRgb;
  };

  const hoverBackgroundColor = (number) => {
    let hoverColorRgb = '';
    switch (number) {
      case 1:
        hoverColorRgb = '#ff4d4d'; // 65
        break;
      case 2:
        hoverColorRgb = '#ffff4d';
        break;
      default:
        hoverColorRgb = '#eee';
        break;
    }
    return hoverColorRgb;
  };

  const updateNotificationRead = async (id, isRead) => {
    if (!isRead) {
      await api.put(`/shared/notificacao/${id}/ConfirmarLeitura`);
      // const result = await api.get(`/shared/notificacao/perfil`);
      // setData(result.data.data);
      // // const notificacoesNaoLidas = result.data.notificacoes.filter(notificacao => !notificacao.lida);
      // setDataTab(result.data.notificacoes || []);
      window.location.reload();
    }
  };

  return (
    <S.Container>
      <S.TabsContainer>
        <S.TabOption isActive={isActive === 'notificacao'} onClick={() => setIsActive('notificacao')}> NOTIFICAÇÃO </S.TabOption>
        <S.TabOption isActive={isActive === 'aviso'} onClick={() => setIsActive('aviso')}> AVISO </S.TabOption>
      </S.TabsContainer>
      <S.FiltersContainer>
        <span style={{ fontSize: '15px', fontWeight: '700' }}>FILTRAR POR</span>
        <S.FiltersOptions isFilterActive={isFilterActive === 'nao-lidas'} onClick={() => setIsFilterActive('nao-lidas')}>
          Não lidas
        </S.FiltersOptions>
        <S.FiltersOptions isFilterActive={isFilterActive === 'lidas'} onClick={() => setIsFilterActive('lidas')}>
          Lidas
        </S.FiltersOptions>
      </S.FiltersContainer>
      <S.NotificationItensContainer>
        {
          dataTab.slice(((currentPage - 1) * 3), ((currentPage - 1) * 3) + 3).map((e) => (
            <S.NotificationItem
              key={e.id}
              priority={backgroundColor(e.prioridade)}
              priorityHover={hoverBackgroundColor(e.prioridade)}
              onClick={() => updateNotificationRead(e.id, e.lida)}
            >
              <span className="material-icons">
                feedback
              </span>
              <S.NotificationItemText>
                <p>{e.titulo}</p>
                <S.NotificationItemIcon>
                  <span className="material-icons-outlined">
                    {e.lida ? 'drafts' : 'email'}
                  </span>
                </S.NotificationItemIcon>
                <S.NotificationItemData>
                  <p>{moment(e.criacao, 'YYYY/MM/DD').format('DD-MM-YYYY')}</p>
                </S.NotificationItemData>
              </S.NotificationItemText>
            </S.NotificationItem>
          ))
        }
      </S.NotificationItensContainer>
      {currentPage === Math.ceil(dataTab.length / 3) && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <NavLink
            to={`/perfil/${currentUser}?notificacoes`}
            className='btn btn-primary'
            style={{ marginTop: '15px', width: '40%', display: 'flex', justifyContent: 'center' }}
          >
            Ver todas notificações
          </NavLink>
        </div>
      )}
      <S.PaginationContainer>
        <span
          className="material-icons"
          style={{ cursor: currentPage === 1 ? 'not-allowed' : 'pointer', color: currentPage === 1 ? 'gray' : 'black' }}
          onClick={() => currentPage !== 1 && setCurrentPage(currentPage - 1)}
        >
          chevron_left
        </span>
        <span style={{ margin: '0px 15px' }}>{currentPage} de {Math.ceil(dataTab.length / 3)}</span>
        <span
          className="material-icons"
          style={{
            cursor: currentPage === Math.ceil(dataTab.length / 3) ? 'not-allowed' : 'pointer',
            color: currentPage === Math.ceil(dataTab.length / 3) ? 'gray' : 'black'
          }}
          onClick={() => currentPage !== Math.ceil(dataTab.length / 3) && setCurrentPage(currentPage + 1)}
        >
          chevron_right
        </span>
      </S.PaginationContainer>
    </S.Container>
  );
}
