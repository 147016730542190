const baseUrl = '/gestao';

const urlsGestao = {
    dashboard: baseUrl,
    alunos: `${baseUrl}/alunos`,
    alunosAdicionar: `${baseUrl}/alunos/adicionar`,
    alunosEditar: `${baseUrl}/alunos/editar/`,
    alunosEditarId: `${baseUrl}/alunos/editar/:id`,
    alunosEvadir: `${baseUrl}/alunos/evadir`,
    alunosAprovacao: `${baseUrl}/alunos/aprovacao`,
    alunosAprovacaoEja: `${baseUrl}/alunos/aprovacao-eja`,
    faltas: `${baseUrl}/faltas`,
    faltasAdicionar: `${baseUrl}/faltas/adicionar/`,
    faltasAdicionarId: `${baseUrl}/faltas/adicionar/:id`,
    diarioClasse: `${baseUrl}/diario-classe`,
    turmas: `${baseUrl}/turmas`,
    bnccFundamental: `${baseUrl}/bncc-fundamental`,
    biblioteca: `${baseUrl}/biblioteca`,
    turmasAdicionar: `${baseUrl}/turmas/adicionar`,
    turmasEditar: `${baseUrl}/turmas/editar/`,
    turmasEditarId: `${baseUrl}/turmas/editar/:id`,
    turmasAlunos: `${baseUrl}/turmas/alunos/`,
    turmasAlunosId: `${baseUrl}/turmas/alunos/:id`,
    servidores: `${baseUrl}/servidores`,
    servidoresAdicionar: `${baseUrl}/servidores/adicionar`,
    servidoresEditar: `${baseUrl}/servidores/editar/`,
    servidoresEditarId: `${baseUrl}/servidores/editar/:id`,
    quadroHorario: `${baseUrl}/quadro-horario`,
    quadroHorarioCadastrar: `${baseUrl}/quadro-horario/cadastrar/`,
    quadroHorarioCadastrarId: `${baseUrl}/quadro-horario/cadastrar/:id`,
    quadroHorarioAlocarDisciplina: `${baseUrl}/quadro-horario/alocar-disciplina/`,
    quadroHorarioAlocarDisciplinaId: `${baseUrl}/quadro-horario/alocar-disciplina/:id`,
    quadroHorarioEditarDisplina: `${baseUrl}/quadro-horario/editar-disciplina/`,
    quadroHorarioEditarDisplinaId: `${baseUrl}/quadro-horario/editar-disciplina/:id`,
    quadroHorarioImpressao: `${baseUrl}/quadro-horario/imprimir`,
    calendario: `${baseUrl}/calendario`,
    perfil: `/perfil`,
    alunoCadastrarFoto: `${baseUrl}/alunos/cadastrar/foto`,
    servidorCadastrarFoto: `${baseUrl}/servidor/cadastrar/foto`,
    alunosCarteirinha: `${baseUrl}/alunos/carteirinha`,
    alunosCarteirinhaImpressao: `${baseUrl}/alunos/carteirinha/impressao`,
    alunosFichaTransporteImpressao: `${baseUrl}/alunos/ficha-transporte/impressao`,
    servidorFrenquenciaLancamento: `${baseUrl}/servidor/frequencia/lancamento`,
}

export default urlsGestao;