import React, { useState } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Breadcrumb from "../../../components/breadcrumb";
import BaseGestao from '../../gestao/baseGestao';
import Doc2Efetivo from './tabs/doc2Efetivo';
import Doc2Contrato from './tabs/doc2Contrato';
import GemEfetivo from './tabs/gemEfetivo';
import GemContrato from './tabs/gemContrato';
import ApoioEfetivo from './tabs/apoioEfetivo';
import ApoioContrato from './tabs/apoioContrato';
import CComissao from './tabs/cComissao';
import HoraExtra from './tabs/horaExtra';

const LancamentosFrenquenciaServidores = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();

  // State to keep track of the selected tab
  const [selectedTab, setSelectedTab] = useState(0);

  // Function to handle tab change
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <BaseGestao>
      <Breadcrumb title={'Formulário Lançamento Frequência Servidore'} itens={['Gestão', 'Servidores']} />
      <div className='row'>
        <div className='col-12'>
          <div className='card'>
            <div className="card-body">

              {/* Tabs Section */}
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={selectedTab} onChange={handleTabChange} aria-label="Frequência Servidores Tabs">
                  <Tab label="DOC. II EFETIVO" />
                  <Tab label="DOC II CONTRATO" />
                  <Tab label="GEM EFETIVO" />
                  <Tab label="GEM CONTRATO" />
                  <Tab label="APOIO EFETIVO" />
                  <Tab label="APOIO CONTRATO" />
                  <Tab label="C. COMISSÃO" />
                  <Tab label="HORA EXTRA" />
                </Tabs>
              </Box>

              {/* Tab Panels */}
              {selectedTab === 0 && <Doc2Efetivo />} {/* Load the Doc2Efetivo content */}
              {selectedTab === 1 && <Doc2Contrato/>}
              {selectedTab === 2 && <GemEfetivo/>}
              {selectedTab === 3 && <GemContrato/>}
              {selectedTab === 4 && <ApoioEfetivo/>}
              {selectedTab === 5 && <ApoioContrato/>}
              {selectedTab === 6 && <CComissao/>}
              {selectedTab === 7 && <HoraExtra/>}

            </div>
          </div>
        </div>
      </div>
    </BaseGestao>
  );
};

// Helper component to display content for each tab
const TabPanel = ({ children }) => {
  return (
    <Box sx={{ p: 3 }}>
      {children}
    </Box>
  );
};

export default LancamentosFrenquenciaServidores;
