import { useMemo, useState, useEffect } from "react";
import BaseGestao from "../gestao/baseGestao";
import BaseGestaoUnidades from "../gestao-unidades/baseGestaoUnidades";
import BaseDocente from "../docente/baseDocente";
import BaseFichaAvaliacao from "../ficha-avaliacao/baseFichaAvaliacao";
import Breadcrumb from "../../components/breadcrumb";
import { useQuery } from "react-query";
import Spinner from "../../components/spinner";
import { useNavigate } from "react-router-dom";
import handleDownloadExcel from "../../../src/utils/handleDownloadExcel";
import {
  getEscolaridades,
  getEscolaridadesUnidade,
} from "../../services/escolaridades";
import { getTurnos, getTurnosUnidadeEscolaridade } from "../../services/turnos";
import { getTurmasUnidadeEscolaridadeTurno } from "../../services/turmas";
import Table from "../../components/table";
import urlsGestao from "../gestao/urlsGestao";
import urlsGestaoUnidades from "../gestao-unidades/urlsGestaoUnidades";
import api from "../../services";
import { toast } from "react-toastify";
import createStorageReportTurmas from "./../../utils/reportStorageTurma";
import urls from "./../../utils/urls";
import Select from "react-select";
import { getUnidades } from "../../services/unidades";
import { Button, Modal, Dropdown } from "react-bootstrap";
import { store } from "../../store";
import { adicionarAvisos } from "../../services/familia-conectada";
import { FilterAltOutlined } from "@mui/icons-material";
import { Menu, Button as ButtonComponet, Chip } from "@mui/material";

const TurmasPage = (gestao) => {
  const navigate = useNavigate();

  let BaseComponent;
  let BasePath;
  let adicionarUrl;
  let alunosUrl;

  // Primeira condição para escolher entre BaseGestao e BaseGestaoUnidades
  if (gestao.gestao) {
    BaseComponent = BaseGestao;
    BasePath = "Gestão";
    adicionarUrl = urlsGestao.turmasAdicionar;
    alunosUrl = urlsGestao.turmasAlunos;
  }
  if (!gestao.gestao && !gestao.docente) {
    BaseComponent = BaseGestaoUnidades;
    BasePath = "Gestão Unidades";
    adicionarUrl = urlsGestaoUnidades.turmasAdicionar;
    alunosUrl = urlsGestaoUnidades.turmasAlunos;
  }

  // Segunda condição para substituir BaseComponent por BaseDocente, se docente.docente for verdadeiro
  if (gestao.docente) {
    BaseComponent = BaseDocente;
    BasePath = "Docente";
    adicionarUrl = urlsGestaoUnidades.turmasAdicionar;
    alunosUrl = urlsGestaoUnidades.turmasAlunos;
  }

  if (gestao.fichaAvaliacao) {
    BaseComponent = BaseFichaAvaliacao;
    BasePath = "Ficha Avaliação";
    adicionarUrl = "";
    alunosUrl = "";
  }

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [titulo, setTitulo] = useState("");
  const [descricao, setDescricao] = useState("");
  const [turmaId, setTurmaId] = useState(0);
  const currentUser = store.getState()["user"]["user"];

  async function adicionarAviso() {
    const data = {
      titulo,
      descricao,
      servidorId: currentUser?.servidorId,
      alunoId: 0,
      turmaId: turmaId,
      unidadeId: unidadeId,
    };

    try {
      let response = await adicionarAvisos(data);
      if (response?.statusCode === 200) {
        toast.success("Aviso adicionado com sucesso");
        handleClose();
      } else {
        toast.error("Erro ao adicionar aviso");
      }
    } catch (error) {
      console.error("Erro ao adicionar aviso:", error);
      toast.error("Erro ao adicionar aviso");
    }
  }

  function handleOpenModal(turmaId) {
    setTurmaId(turmaId);
    setShow(true);
  }

  const anoLetivoAtual =
    process.env.REACT_APP_SECRETARIA_MUNICIPAL !== "SEMED" ? 0 : 2024;
  const [unidades, setUnidades] = useState([]);
  const [openPicker, setOpenPicker] = useState(false);
  const onMenuOpen = () => setIsOpen(true);
  const onMenuClose = () => setIsOpen(false);
  const [isOpen, setIsOpen] = useState(false);

  const [exportar, setExportar] = useState(0);
  const nomeArquivo = "relatorio-turmas.xlsx";
  const filtroTurmaAnoLetivo =
    localStorage.getItem("filtroTurmasAnoLetivo") || anoLetivoAtual;
  const filtroTurmaEscolaridade =
    localStorage.getItem("filtroTurmasEscolaridade") || 0;
  const filtroTurmaTurno = localStorage.getItem("filtroTurmasTurno") || 0;

  const unidadeId = localStorage.getItem("unidadeID");
  const [unidade, setUnidade] = useState(gestao.gestao ? 0 : unidadeId);
  const [escolaridade, setEscolaridade] = useState(filtroTurmaEscolaridade);
  const [anoLetivo, setAnoLetivo] = useState(filtroTurmaAnoLetivo);
  const [turno, setTurno] = useState(filtroTurmaTurno);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(20);
  const [data, setData] = useState([]);

  const { isLoading: loadingUnidades, refetch: refetchUnidades } = useQuery(
    "getUnidades",
    () => getUnidades(),
    {
      enabled: true,
      retry: true,
      onSuccess: (data) => {
        var dados = [{ value: 0, label: "TODOS" }];
        data.forEach((item) => {
          dados.push({ value: item.id, label: item.nome });
        });

        setUnidades(dados);
      },
    }
  );

  const anosLetivos = [{ id: 2024, value: 2024 }];

  if (process.env.REACT_APP_SECRETARIA_MUNICIPAL === "SEDUC") {
    anosLetivos.unshift({ id: 2023, value: 2023 });
  }

  const {
    refetch: refetchTurmas,
  } = useQuery(
    "getTurmas",
    () =>
      getTurmasUnidadeEscolaridadeTurno(
        unidade,
        escolaridade,
        turno,
        anoLetivo,
        skip,
        limit
      ),
    {
      retry: 0,
      enabled: false,
      onSuccess: (data) => {
        setData(data);
      },
    }
  );

  const {
    data: escolaridades,
    isLoading: loadingEscolaridades,
    refetch: refetchEscolaridades,
  } = useQuery(
    "getEscolaridades",
    () => (unidade > 0 ? getEscolaridadesUnidade(unidade) : getEscolaridades()),
    {
      retry: 0,
      enabled: false,
    }
  );

  const {
    data: turnos,
    isLoading: loadingTurnos,
    refetch: refetchTurnos,
  } = useQuery(
    "getTurnos",
    () =>
      unidade > 0 && escolaridade > 0
        ? getTurnosUnidadeEscolaridade(unidade, escolaridade)
        : getTurnos(),
    {
      retry: 0,
      enabled: true,
    }
  );

  const cleanFilters = async (name) => {
    switch (name) {
      case "unidade":
        await setEscolaridade(0);
        await setTurno(0);
        await localStorage.setItem("filtroTurmasEscolaridade", 0);
        await localStorage.setItem("filtroTurmasTurno", 0);
        break;
      case "escolaridade":
        await setTurno(0);
        await localStorage.setItem("filtroTurmasTurno", 0);
        break;
      case "turno":
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    refetchTurmas();
    refetchEscolaridades();
  }, [refetchTurmas, refetchEscolaridades, limit, skip]);

  const handleDelete = async (id) => {
    try {
      var response = await api.delete(`turma/${id}`);
      toast.success("Turma excluída com sucesso!");
      refetchTurmas();
    } catch (err) {
      toast.error("Falha na exclusão da turma. Tente novamente mais tarde!");
    }
  };

  const clickImprimirTurmas = async () => {
    var data = await getTurmasUnidadeEscolaridadeTurno(
      unidade,
      escolaridade,
      turno,
      anoLetivo,
      skip,
      5000
    );
    var listaDados = [];
    data.data.map((x) => {
      listaDados.push({
        id: x.id,
        unidadeId: x.unidadeId,
        escolaridadeId: x.escolaridadeId,
        turnoId: x.turnoId,
        nomeTurma: x.descricao,
        vagasTotais: x.vagasTotais,
        vagasRegulares: x.vagas,
        vagasPcd: x.vagasPcd,
        alunosRegulares: x.quantidadeAlunosRegulares,
        alunosPcd: x.quantidadeAlunosPcdPne,
        saldo: x.saldo,
      });
    });

    await createStorageReportTurmas({
      dados: listaDados,
      escolaridade: escolaridade,
      unidade: unidade,
      todasUnidades: unidades,
    });
    window.open(urls.relatorio, "_blank");
  };

  const exportarDados = async () => {
    if (data.length === 0 || data?.total === 0) {
      return toast.warning("Não há dados para exportar.");
    }

    try {
      const relatorioData = await getTurmasUnidadeEscolaridadeTurno(
        unidade,
        escolaridade,
        turno,
        anoLetivo,
        skip,
        5000,
        1
      );

      if (relatorioData) {
        return handleDownloadExcel(relatorioData, nomeArquivo);
      } else {
        return toast.error("Falha ao obter o relatório");
      }
    } catch (error) {
      return toast.error("Erro ao buscar relatório");
    }
  };

  const ActionsMenu = ({ row }) => {
    return (
      <Dropdown>
        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
          Ações
        </Dropdown.Toggle>

        <Dropdown.Menu style={{ marginTop: 0 }}>
          {row.original.quantidadeAlunosRegulares === 0 &&
            row.original.quantidadeAlunosPcdPne === 0 &&
            gestao.gestao && (
              <Dropdown.Item
                className="dropdown-item-danger d-flex"
                onClick={() => handleDelete(row.original.id)}
              >
                <i className="material-icons-two-tone pe-2">remove</i> Excluir
              </Dropdown.Item>
            )}
          {!(gestao.fichaAvaliacao || gestao.docente) && (
            <Dropdown.Item
              className="dropdown-item-primary d-flex"
              onClick={() =>
                navigate(adicionarUrl, {
                  state: {
                    id: row.original.id,
                    turma: {
                      descricao: row.original.descricao,
                      turnoID: row.original.turnoId,
                      vagas: row.original.vagas,
                      metragemSala: row.original.metragemSala,
                      escolaridadeID: row.original.escolaridadeId,
                      anoLetivo: row.original.anoLetivo,
                      vagasPcd: row.original.vagasPcd,
                      preMatricula: row.original.preMatricula,
                      vagasTotais: row.original.vagasTotais,
                      dataAbertura: row.original.dataAbertura,
                      dataFechamento: row.original.dataFechamento,
                    },
                  },
                })
              }
            >
              <i className="material-icons-two-tone pe-2">edit_note</i>{" "}
              Visualizar
            </Dropdown.Item>
          )}
          {!(gestao.fichaAvaliacao || gestao.docente) && (
            <Dropdown.Item
              className="dropdown-item-success d-flex"
              onClick={() =>
                navigate(alunosUrl + row.original.id, {
                  state: {
                    id: row.original.id,
                    turma: {
                      descricao: row.original.descricao,
                      turnoID: row.original.turnoId,
                      vagas: row.original.vagas,
                      metragemSala: row.original.metragemSala,
                      escolaridadeID: row.original.escolaridadeId,
                      anoLetivo: row.original.anoLetivo,
                      vagasPcd: row.original.vagasPcd,
                      preMatricula: row.original.preMatricula,
                    },
                  },
                })
              }
            >
              <i className="material-icons pe-2">groups</i> Alunos
            </Dropdown.Item>
          )}
          {(gestao.fichaAvaliacao || gestao.docente) && (
            <Dropdown.Item
              className="dropdown-item-success"
              onClick={() =>
                navigate(alunosUrl + row.original.id, {
                  state: {
                    id: row.original.id,
                    turma: {
                      descricao: row.original.descricao,
                      turnoID: row.original.turnoId,
                      vagas: row.original.vagas,
                      metragemSala: row.original.metragemSala,
                      escolaridadeID: row.original.escolaridadeId,
                      anoLetivo: row.original.anoLetivo,
                      vagasPcd: row.original.vagasPcd,
                      preMatricula: row.original.preMatricula,
                    },
                  },
                })
              }
            >
              <i className="material-icons-two-tone pe-2">edit_note</i> Fichas
            </Dropdown.Item>
          )}
          <Dropdown.Item
            className="dropdown-item-warning d-flex"
            onClick={() => handleOpenModal(row.original.id)}
          >
            <i className="material-icons-two-tone pe-2">priority_high</i>{" "}
            Notificar
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const columns = useMemo(
    () => [
      { Header: "#", accessor: "id" },
      { Header: "Nome Turma", accessor: "descricao" },
      { Header: "PreMatricula", accessor: "preMatricula" },
      { Header: "Vagas Totais", accessor: "vagasTotais" },
      { Header: "Vagas Regulares", accessor: "vagas" },
      { Header: "Vagas PcD", accessor: "vagasPcd" },
      { Header: "Alunos Regulares", accessor: "quantidadeAlunosRegulares" },
      { Header: "Alunos PcD", accessor: "quantidadeAlunosPcdPne" },
      { Header: "Data Abertura", accessor: "dataAbertura" },
      { Header: "Data Fechamento", accessor: "dataFechamento" },
      {
        Header: "Saldo",
        accessor: "saldo",
        Cell: ({ row }) => {
          let saldo = row.original.saldo;

          return (
            <span
              style={{
                color: saldo < 0 ? "red" : "inherit",
                fontWeight: saldo < 0 ? "bold" : "normal",
              }}
            >
              {saldo}
            </span>
          );
        },
      },
      {
        Header: "",
        accessor: "actions",
        Cell: ({ row }) => <ActionsMenu row={row} />,
      },
    ],
    [navigate]
  );

  function renderBreadcrumb() {
    if (gestao.gestao) {
      return (
        <Breadcrumb
          title={"Turmas"}
          itens={[BasePath, "Turmas", "Lista Geral"]}
          actions={{ link: adicionarUrl, text: "Nova Turma" }}
          imprimir={{ link: urls.relatorio, text: "Imprimir" }}
          onClickImprimir={clickImprimirTurmas}
          hasExcelButton
          onClickDownload={() => exportarDados()}
        />
      );
    } else {
      return (
        <Breadcrumb
          title={"Turmas"}
          itens={[BasePath, "Turmas", "Lista Geral"]}
        />
      );
    }
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseB = () => {
    setAnchorEl(null);
  };

  const unidadeSelecionada = unidades?.find((x) => x.value == unidade)?.label;
  const escolaridadeSelecionada = escolaridades?.find(
    (x) => x.id == escolaridade
  )?.descricao;
  const turnoSelecionado = turnos?.find((x) => x.id == turno)?.descricao;

  return (
    <BaseComponent>
      {renderBreadcrumb()}
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div
              style={{
                margin: "10px",
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                gap: "12px",
              }}
            >
              <ButtonComponet
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                startIcon={<FilterAltOutlined />}
                variant="contained"
                size="large"
                color="info"
              >
                Filtro
              </ButtonComponet>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseB}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <div style={{ padding: "20px", width: "400px" }}>
                  <div className="form-group">
                    <label htmlFor="unidadeId" className="form-label">
                      Unidades
                    </label>
                    <Select
                      aria-labelledby="aria-label"
                      inputId="aria-example-input"
                      name="aria-live-color"
                      onMenuOpen={onMenuOpen}
                      onMenuClose={onMenuClose}
                      options={unidades}
                      isDisabled={gestao.gestao ? false : true}
                      value={unidades?.find((x) => x.value == unidade)}
                      placeholder=""
                      onChange={async (e) => {
                        await setUnidade(e.value);
                        cleanFilters("unidade");
                        await refetchEscolaridades();
                        await refetchTurnos();
                        await refetchTurmas();
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="anoLetivo" className="form-label">
                      Ano Letivo
                    </label>
                    <select
                      className="form-select"
                      id="anoLetivo"
                      value={anoLetivo}
                      onChange={async (e) => {
                        await setAnoLetivo(e.target.value);
                        localStorage.setItem(
                          "filtroTurmasAnoLetivo",
                          e.target.value
                        );
                        refetchTurmas();
                      }}
                    >
                      <>
                        {process.env.REACT_APP_SECRETARIA_MUNICIPAL !==
                          "SEMED" && <option value={0}></option>}
                        {anosLetivos &&
                          anosLetivos.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.value}
                            </option>
                          ))}
                      </>
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="escolaridadeID" className="form-label">
                      Escolaridades
                    </label>
                    <select
                      className="form-select"
                      id="escolaridadeID"
                      value={escolaridade}
                      onChange={async (e) => {
                        await setEscolaridade(e.target.value);
                        localStorage.setItem(
                          "filtroTurmasEscolaridade",
                          e.target.value
                        );
                        cleanFilters("escolaridade");
                        refetchTurnos();
                        refetchTurmas();
                      }}
                    >
                      {loadingEscolaridades ? (
                        <option value={0}>TODOS</option>
                      ) : (
                        <>
                          <option value={0}>TODOS</option>
                          {escolaridades &&
                            escolaridades.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.descricao}
                              </option>
                            ))}
                        </>
                      )}
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="turnoID" className="form-label">
                      Turnos
                    </label>
                    <select
                      className="form-select"
                      id="turnoID"
                      value={turno}
                      onChange={async (e) => {
                        await setTurno(e.target.value);
                        localStorage.setItem(
                          "filtroTurmasTurno",
                          e.target.value
                        );
                        refetchTurmas();
                      }}
                    >
                      {loadingTurnos ? (
                        <option value={0}>TODOS</option>
                      ) : (
                        <>
                          <option value={0}>TODOS</option>
                          {turnos &&
                            turnos.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.descricao}
                              </option>
                            ))}
                        </>
                      )}
                    </select>
                  </div>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Chip
                      sx={{ marginTop: 2 }}
                      color="error"
                      label="Limpar filtros"
                      onClick={async () => {
                        if (gestao.gestao) {
                          await setUnidade(0);
                          cleanFilters("unidade");
                        }
                        await refetchEscolaridades();
                        await refetchTurnos();
                        setEscolaridade(0)
                        setTurno(0)
                        await localStorage.removeItem("filtroTurmasAnoLetivo");
                        await localStorage.removeItem(
                          "filtroTurmasEscolaridade"
                        );
                        await localStorage.removeItem("filtroTurmasTurno");
                        cleanFilters("escolaridade");
                        await refetchTurmas();
                      }}
                    />
                  </div>
                </div>
              </Menu>
              {unidade > 0 ? (
                <Chip
                  color="info"
                  label={`UNIDADE: ${unidadeSelecionada}`}
                  variant="outlined"
                  {...(gestao.gestao && {
                    onDelete: async () => {
                      await setUnidade(0);
                      await setEscolaridade(0);
                      await setTurno(0);
                      refetchTurmas();
                      refetchEscolaridades();
                    },
                  })}
                />
              ) : null}
              {anoLetivo > 0 ? (
                <Chip
                  color="info"
                  label={`ANO LETIVO: ${anoLetivo}`}
                  variant="outlined"
                  onDelete={async () => {
                    await setAnoLetivo(0);
                    await localStorage.removeItem("filtroTurmasAnoLetivo");
                    await refetchTurmas();
                  }}
                />
              ) : null}
              {escolaridade > 0 ? (
                <Chip
                  color="info"
                  label={`ESCOLARIDADE: ${escolaridadeSelecionada}`}
                  variant="outlined"
                  onDelete={async () => {
                    await setEscolaridade(0);
                    await setTurno(0);
                    await refetchTurnos(0);
                    await localStorage.removeItem("filtroTurmasEscolaridade");
                    await localStorage.removeItem("filtroTurmasTurno");
                    await localStorage.removeItem("filtroTurmasTurma");
                    await refetchTurmas();
                  }}
                />
              ) : null}
              {turno > 0 ? (
                <Chip
                  color="info"
                  label={`TURNO: ${turnoSelecionado}`}
                  variant="outlined"
                  onDelete={async () => {
                    await setTurno(0);
                    await localStorage.removeItem("filtroTurmasTurno");
                    await localStorage.removeItem("filtroTurmasTurma");
                    await refetchTurmas();
                  }}
                />
              ) : null}
            </div>
            <Table
              columns={columns}
              data={data?.data ? data?.data : []}
              hasPagination
              limit={data?.limit}
              setLimit={setLimit}
              skip={data?.skip}
              setSkip={setSkip}
              totalItems={data?.total}
              hiddenColluns={["preMatricula", "dataAbertura", "dataFechamento"]}
              overflowUnset={true}
            />
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Adicionar Aviso | Turma</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="form-group" style={{ width: "100%" }}>
              <label htmlFor="titulo" className="form-label">
                TÍTULO
              </label>
              <textarea
                onChange={(e) => {
                  setTitulo(e.target.value);
                }}
                value={titulo}
                className="form-control"
                id="titulo"
                rows="1"
                placeholder="REUNIÃO"
              ></textarea>
            </div>
          </div>
          <div className="row">
            <div className="form-group" style={{ width: "100%" }}>
              <label htmlFor="descricao" className="form-label">
                DESCRIÇÃO
              </label>
              <textarea
                onChange={(e) => {
                  setDescricao(e.target.value);
                }}
                value={descricao}
                className="form-control"
                id="descricao"
                rows="3"
                placeholder="REUNIÃO DE PAIS DIA ..."
              ></textarea>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="primary" onClick={adicionarAviso}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </BaseComponent>
  );
};

export default TurmasPage;
