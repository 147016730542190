// src/components/tabs/doc2Efetivo.js
import React from 'react';
import { Box } from '@mui/material';

const GemContrato = () => {
  return (
    <Box sx={{ p: 3 }}>
      DOC. II EFETIVO Content
    </Box>
  );
};

export default GemContrato;
