import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import Notifications from '../notificacoes'
import api from '../../services/index'
import { Link } from 'react-router-dom';
import { store } from "../../store";

const Logo = ({perfil = true}) => {
  const isMenuOpen = useSelector(state => state.panel.isMenuOpen);
  
  // const { user } = JSON.parse(localStorage.getItem('persist:root'));
  // const { user: userData } = JSON.parse(user);

  const currentUser = store.getState()["user"]["user"];


  const modulosLink = perfil === true ? '/modulos' : '#';

  const [notificationData, setNotificationData] = useState();

  

  const [shouldOpenNotificationOptions, setShouldOpenNotificationOptions] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const notifications = await api.get('/shared/notificacao/perfil');
        setNotificationData(notifications);
        // setDataTab(notifications.data.notificacoes || []);
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    }
    fetchData();
  }, []);

  const backgroundStyle = {
    backgroundImage: `url(/assets/images/prefeituras/${process.env.REACT_APP_SECRETARIA_MUNICIPAL.toLowerCase()}.png)`,
    backgroundRepeat: 'no-repeat',
    // Add more styling here as needed, such as backgroundSize, backgroundPosition, etc.
  };

  return (
    <div className={isMenuOpen ? "logo canvas-sidebar-hidden-logo" : "logo"} style={{ overflow: 'inherit' }}>
      <a href={modulosLink} className="logo-icon" style={backgroundStyle}>
        <span className="logo-text">{process.env.REACT_APP_SECRETARIA_CIDADE}</span>
      </a>
      {perfil === true && (
        <div className="sidebar-user-switcher" style={{ position: 'relative' }}>
        <Link style={{ display: "flex", flexDirection: "row-reverse", alignItems: "center" }} to="/perfil" title="Perfil">
        {currentUser?.fotoPerfil !== '' && (
             <img src={currentUser?.fotoPerfil}  alt="avatar perfil" 
             style={{
              width: "42px",
              height: "42px", // Fixed height to ensure the image is square
              borderRadius: "24px",
              objectFit: "cover", // Ensures the image covers the entire element while maintaining aspect ratio
              objectPosition: "center", // Centers the image within the element
          }}
             />
        )} 
        {currentUser?.fotoPerfil === '' && (
             <img src="/assets/images/avatars/avatar-male.svg" alt="avatar perfil" />
        )}
          <span className="activity-indicator" />
          <span className="user-info-text">
            {currentUser?.usuarioLogin.split('.')[0] || 'Usuário'}
            {/* <br /> */}
            {/* <span className="user-state-info">{currentUser?.usuarioLogin}</span> */}
          </span>
        </Link>
        {notificationData?.data?.data?.totalNotificacoesNaoLidas > 0 && (
          <>
          <div
          onClick={() => setShouldOpenNotificationOptions(!shouldOpenNotificationOptions)}
          style={{
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '20px',
            height: '20px',
            backgroundColor: '#FF9500',
            borderRadius: '50%',
            cursor: 'pointer',
            top: '-3px',
            right: '-9px'
          }}
        >
            <span style={{ fontSize: '12px', color: '#FFF' }}>{notificationData?.data?.data?.totalNotificacoesNaoLidas}</span>
        </div>
          </>
        )}
        
        {shouldOpenNotificationOptions && <Notifications notifications={notificationData}/>}
      </div>
      )}
      
    </div>
  )
};

export default Logo;