import React, { useState, useEffect } from 'react'
import FormInput from '../../../../../components/formInput'
import { useQuery } from 'react-query'
import { getEscolaridades } from '../../../../../services/escolaridades'
import { getTurnos } from '../../../../../services/turnos'

export default function DadosTurma({ register, errors, setValue, canEdit, isEditPage, escolaridadeInfo, watch }) {
  const unidade = localStorage.getItem('unidadeID');
  const [escolaridade, setEscolaridade] = useState(escolaridadeInfo)
  const [turno, setTurno] = useState(null)
  

  const { data: escolaridades, isLoading: loadingEscolaridades } = useQuery(
    'getEscolaridades',
    () => (unidade !== null ? getEscolaridades() : null),
    {
      retry: 3,
      enabled: unidade !== null
    }
  )

  const pesoVagaPne = 2
  const [totalVagas, setTotalVagas] = useState(0)

  useEffect(() => {

    if (process.env.REACT_APP_SECRETARIA_CIDADE === 'ARARUAMA'){
      const vagas = watch('vagas')
      const vagasPne = watch('vagasPcd')
  
      let totalVagas = parseInt(vagas) + (parseInt(vagasPne) * parseInt(pesoVagaPne))
  
      setTotalVagas(totalVagas)
      setValue('vagasTotais', totalVagas)
    }

    
    
  }, [watch('vagas'), watch('vagasPcd')])


  const {
    data: turnos,
    isLoading: loadingTurnos,
    refetch: refetchTurnos
  } = useQuery(
    'getTurnos',
    () => getTurnos(),
    {
      retry: 3,
    }
  )

  useEffect(() => {
    if (isEditPage) {
      refetchTurnos()
    }
  }, [isEditPage])

  return (
    <div
      className="tab-pane fade show active"
      id="dadosTurma"
      role="tabpanel"
      aria-labelledby="dadosTurma-tab"
    >
      <div className="card-body">
        <div className="row" style={{ paddingRight: 0 }}>
          {
            isEditPage && (
              <div className="col-md-1">
                <FormInput
                  register={register}
                  errors={errors}
                  group=""
                  atribute="id"
                  label="Turma ID"
                  readOnly={true}
                  
                />
              </div>
            )
          }
          <div className={isEditPage ? "col-md-2" : "col-md-2"}>
            <FormInput
              register={register}
              errors={errors}
              group=""
              atribute="descricao"
              label="Nome Turma"
              readOnly={!canEdit}
            />
          </div>

          <div className={isEditPage ? "col-md-2" : "col-md-2"}>
            <FormInput
              register={register}
              errors={errors}
              type='number'
              atribute="vagasTotais"
              label={process.env.REACT_APP_SECRETARIA_CIDADE === 'ARARUAMA' ? 'Vagas Totais' : 'Total de Vagas'}
              readOnly={process.env.REACT_APP_SECRETARIA_CIDADE === 'ARARUAMA' ? true : !canEdit}
              value={process.env.REACT_APP_SECRETARIA_CIDADE === 'ARARUAMA' ? totalVagas : ''}
            />
          </div>

          <div className={isEditPage ? "col-md-1" : "col-md-2"}>
            <FormInput
              register={register}
              errors={errors}
              type='number'
              atribute="vagas"
              label="Vagas"
              readOnly={!canEdit}
            />
          </div>

          <div className={isEditPage ? "col-md-2" : "col-md-2"}>
            <FormInput
              register={register}
              errors={errors}
              type='number'
              atribute="vagasPcd"
              label="Vagas PCD"
              readOnly={!canEdit}
            />
          </div>

          <div className={isEditPage ? "col-md-2" : "col-md-2"}>
            <FormInput
              register={register}
              errors={errors}
              group=""
              atribute="metragemSala"
              label="Metragem Sala"
              readOnly={!canEdit}
            />
          </div>

          <div className="col-md-2">
            <div className="col-md 2">
              <div className="form-group">
                <label htmlFor="preMatricula" className="form-label">
                  Turmas Pré-Matrícula?
                </label>
                <select
                  className="form-select"
                  id="preMatricula"
                  {...register('preMatricula', { required: true })}
                  onChange={async e => {
                    await setValue('preMatricula', e.target.value)
                  }}
                  disabled={!canEdit ? true : false}
                >
                  
                    <>
                    <option value=''></option>
                      <option value={1}>SIM</option>
                      <option value={2}>NÃO</option>
                      
                    </>
                </select>
                {errors.escolaridadeID && (
                  <span className="text-danger">Campo obrigatório</span>
                )}
              </div>
            </div>
          </div>

          <div className="col-md-12" style={{ marginTop: 30 }}>
            <FormInput
              register={register}
              errors={errors}
              group=""
              atribute="unidadeID"
              label="Unidade"
              readOnly
            />
          </div>

          <div className="col-md-4" style={{ marginTop: 30 }}>
            <div className="col-md 4">
              <div className="form-group">
                <label htmlFor="anoLetivo" className="form-label">
                  Ano Letivo
                </label>
                <select
                  className="form-select"
                  id="anoLetivo"
                  {...register('anoLetivo', { required: true })}
                  onChange={async e => {
                    await setValue('anoLetivo', e.target.value)
                  }}
                  disabled={isEditPage ? true : false}
                >
                  
                    <>
                    <option value=''></option>
                      <option value={2023}>2023</option>
                      <option value={2024}>2024</option>
                      
                    </>
                </select>
                {errors.escolaridadeID && (
                  <span className="text-danger">Campo obrigatório</span>
                )}
              </div>
            </div>
          </div>

          <div className="col-md-4" style={{ marginTop: 30 }}>
            <div className="col-md 4">
              <div className="form-group">
                <label htmlFor="escolaridadeID" className="form-label">
                  Escolaridades
                </label>
                <select
                  className="form-select"
                  id="escolaridadeID"
                  {...register('escolaridadeID', { required: true })}
                  onChange={async e => {
                    await setValue('escolaridadeID', e.target.value)
                    await setEscolaridade(e.target.value)
                    await setValue('turnoID', '')
                    refetchTurnos()
                  }}
                  disabled={!canEdit}
                >
                  {loadingEscolaridades ? (
                    <option value="">Carregando...</option>
                  ) : (
                    <>
                      <option value=""></option>
                      {escolaridades &&
                        escolaridades.map(item => (
                          <option key={item.id} value={item.id}>
                            {item.descricao}
                          </option>
                        ))}
                    </>
                  )}
                </select>
                {errors.escolaridadeID && (
                  <span className="text-danger">Campo obrigatório</span>
                )}
              </div>
            </div>
          </div>

          <div className="col-md-4" style={{ marginTop: 30 }}>
            <div className="col-md 4">
              <div className="form-group">
                <label htmlFor="turnoID" className="form-label">
                  Turnos
                </label>
                <select
                  className="form-select"
                  id="turnoID"
                  {...register('turnoID', { required: true })}
                  onChange={async e => {
                    await setValue('turnoID', parseInt(e.target.value))
                    await setTurno(parseInt(e.target.value))
                  }}
                  disabled={!canEdit}
                >
                  {loadingTurnos ? (
                    <option value="">Carregando...</option>
                  ) : (
                    <>
                      <option value=""></option>
                      {turnos &&
                        turnos.map(item => (
                          <option key={item.id} value={item.id}>
                            {item.descricao}
                          </option>
                        ))}
                    </>
                  )}
                </select>
                {errors.turnoID && (
                  <span className="text-danger">Campo obrigatório</span>
                )}
              </div>
            </div>
          </div>
          {/* <div class name="row" style={{ marginTop: 30 }}> */}
          <div className="col-md-6" style={{ marginTop: 20 }}>
            <FormInput
              register={register}
              errors={errors}
              group=""
              atribute="dataAbertura"
              label="Data Abertura"
              type='date'
              readOnly={!canEdit}
              // defaultValue={new Date().toISOString().split('T')[0]}
            />
          </div>
          <div className="col-md-6" style={{ marginTop: 20 }}>
            <FormInput
              register={register}
              errors={errors}
              group=""
              atribute="dataFechamento"
              label="Data Fechamento"
              type='date'
              readOnly={!canEdit}
            />
          </div>
            {/* </div> */}
        </div>
      </div>
    </div>
  )
}
