import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Breadcrumb from "../../../components/breadcrumb";
import BaseConfiguracoes from "../../configuracoes/baseConfiguracoes";
import urlsConfiguracoes from "../../configuracoes/urlsConfiguracoes";
import { useMutation } from 'react-query';
import { updateCargo, createCargo } from "../../../services/cargos";
import upperCaseSimple from "../../../utils/upperCaseSimple";
import removeEmpty from "../../../utils/removeEmpty";

const CargosCadastrarPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();

  const { mutate, isLoading } = useMutation(id ? updateCargo : createCargo, {
    onSuccess: (message) => {
      toast.success(message);
      navigate(urlsConfiguracoes.cargos);
    },
    onError: (error) => {
      if (error.response) {
        const { message } = error.response.data;
        toast.error(message);
      } else if (error.request) {
        toast.error('Erro ao tentar cadastrar um cargo, tente novamente mais tarde.');
      } else {
        toast.error(error.message);
      }
    }
  });

  const { register, handleSubmit, setValue, formState: { isValid, errors } } = useForm({
    mode: "onChange", defaultValues: {
      descricao: '',
      ativo: true,
      cargoFrequenciaDoc2: false,
      cargoFrequenciaGem: false,
      cargoFrequenciaApoio: false,
    }
  });

  const onSubmit = (data) => {
    data.ativo = data.ativo == 'false' ? false : true;
    data.cargoFrequenciaDoc2 = data.cargoFrequenciaDoc2.toLower() == 'false' ? false : true;
    data.cargoFrequenciaGem = data.cargoFrequenciaGem.toLower() == 'false' ? false : true;
    data.cargoFrequenciaApoio = data.cargoFrequenciaApoio.toLower() == 'false' ? false : true;
    if (id) {
      mutate({ id, data: upperCaseSimple(removeEmpty(data)) });
    } else {
      mutate(upperCaseSimple(removeEmpty(data)));
    }
  };

  useMemo(() => {
    (async () => {
      if (state) {
        const { cargo } = state;
        if (id && cargo) {
          const { descricao, ativo } = cargo;
          setValue('descricao', descricao);
          setValue('ativo', ativo);
          setValue('cargoFrequenciaDoc2', cargo.cargoFrequenciaDoc2);
          setValue('cargoFrequenciaGem', cargo.cargoFrequenciaGem);
          setValue('cargoFrequenciaApoio', cargo.cargoFrequenciaApoio);
        }
      }
    })();
  }, [id, state, setValue]);

  return (
    <BaseConfiguracoes>
      <Breadcrumb title={'Formulário Cargo'} itens={['Gestao', 'Acadêmico', 'Cargos', 'Formulário']} />
      <form id='form' onSubmit={handleSubmit(onSubmit)}>
        <div className='row'>
          <div className='col-12'>
            <div className='card'>
              <div className="card-body">
                <>
                  <div className='row'>
                    <div className='col-12'>
                      <label htmlFor="descricao" className="form-label">Descrição</label>
                      <input type="text" className="form-control" id="descricao"
                        {...register("descricao", { required: true })} />
                      {errors.descricao?.type === 'required' && <div role='alert' className="invalid-feedback d-block">Campo obrigatório!</div>}
                    </div>
                  </div>

                  <br />

                  <div className='row'>
                    <div className='col-12'>
                      <label htmlFor="ativo" className="form-label">Status</label>
                      <select className="form-select" id="ativo" {...register("ativo", { required: true })}>
                        <option value="true">ATIVO</option>
                        <option value="false">INATIVO</option>
                      </select>
                      {errors.ativo?.type === 'required' && <div role='alert' className="invalid-feedback d-block">Campo obrigatório!</div>}
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-12'>
                      <label htmlFor="cargoFrequenciaDoc2" className="form-label">Utilizar em Frequência DOC II?</label>
                      <select className="form-select" id="cargoFrequenciaDoc2" {...register("cargoFrequenciaDoc2", { required: true })}>
                      <option value={false}>NÃO</option>
                      <option value={true}>SIM</option>
                      </select>
                      {/* {errors.cargoFrequenciaDoc2?.type === 'required' && <div role='alert' className="invalid-feedback d-block">Campo obrigatório!</div>} */}
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-12'>
                      <label htmlFor="cargoFrequenciaGem" className="form-label">Utilizar em Frequência GEM?</label>
                      <select className="form-select" id="cargoFrequenciaGem" {...register("cargoFrequenciaGem", { required: true })}>
                      <option value={false}>NÃO</option>
                      <option value={true}>SIM</option>
                      </select>
                      {/* {errors.cargoFrequenciaGem?.type === 'required' && <div role='alert' className="invalid-feedback d-block">Campo obrigatório!</div>} */}
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-12'>
                      <label htmlFor="cargoFrequenciaApoio" className="form-label">Utilizar em Frequência Apoio?</label>
                      <select className="form-select" id="ativo" {...register("cargoFrequenciaApoio", { required: true })}>
                        <option value={false}>NÃO</option>
                      <option value={true}>SIM</option>
                      </select>
                      {/* {errors.cargoFrequenciaApoio?.type === 'required' && <div role='alert' className="invalid-feedback d-block">Campo obrigatório!</div>} */}
                    </div>
                  </div>
                </>
              </div>
              <div className='card-footer text-end'>
                <button type='submit' className='btn btn-success'>
                  {isLoading ? 'enviando...' : id ? 'Atualizar' : 'Cadastrar'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </BaseConfiguracoes>
  )

};

export default CargosCadastrarPage;